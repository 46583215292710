import {
  PATH_CHALLENGE_DETAIL_PARTICIPANTS,
  PATH_CHALLENGE_DETAIL_V2,
  PATH_SUB_HABIT_QUESTION_DETAIL,
  PATH_EDIT_POST,
  PATH_MISSION_DETAIL_CREATE,
  PATH_GROUP_MANAGEMENT,
  PATH_GROUP_CREATE,
  PATH_GROUP_DETAIL,
  PATH_EMAIL_MANAGEMENT,
  PATH_EMAIL_CREATE,
  PATH_EMAIL_DETAIL,
  PATH_SUB_MEMBERSHIP_PAGE,
  PATH_SUB_DAILY_ROUTINE,
  PATH_DAILY_ROUTINE_DETAIL,
  PATH_DAILY_ROUTINE_DETAIL_CREATE,
  PATH_DAILY_ROUTINE_JOBS,
  PATH_DAILY_ROUTINE_JOBS_DETAIL,
  PATH_SUB_SETUP_DAILY_ROUTINE,
  PATH_HOME_PAGE_MANAGEMENT,
  PATH_HOME_CONTENT_DETAIL,
} from './navigation'
import { IRoute } from '@interfaces'
import {
  ChangePasswordPage,
  Dashboard,
  ForgotPasswordPage,
  LoginPage,
  PackageManagementPage,
  ResetPasswordPage,
  StaffAndRoleManagementPage,
  UserDetailPage,
  UserManagementPage,
} from '@pages'
import { ContactPage } from 'src/components/declaration/contact/ContactPage'
import AboutUsPageManagement from 'src/pages/about-us-page-management/AboutUsPageManagement'
import { InitPasswordAdminPage } from 'src/pages/auth/InitPasswordAdminPage'
import { ProfilePage } from 'src/pages/auth/ProfilePage'
import BlogContent from 'src/pages/blog/BlogContent'
import BlogNewsPage from 'src/pages/blog/BlogNewsPage'
import SingleBlog from 'src/pages/blog/SingleBlog'
import { Category, SubCategory } from 'src/pages/category'
import DeclarationPage from 'src/pages/declaration/DeclarationPage'
import EditorPage from 'src/pages/editor/EditorPage'
import GalleryScreen from 'src/pages/gallery/GalleryScreen'
import ChallengeHabitPage from 'src/pages/habit-management/challenge/ChallengeHabitPage'
import DetailChallenge from 'src/pages/habit-management/challenge/DetailChallenge'
import DetailChallengeParticipant from 'src/pages/habit-management/challenge/DetailChallengeParticipant'
import DetailChallenge_V2 from 'src/pages/habit-management/challenge/DetailChallenge_V2'
import DetailMission from 'src/pages/habit-management/challenge/DetailMission'
import MissionPage from 'src/pages/habit-management/challenge/MissionPage'
import TagHabitPage from 'src/pages/habit-management/goal/GoalHabitPage'
import QuestionDetailPage from 'src/pages/habit-management/question/QuestionDetailPage'
import QuestionHabitPage from 'src/pages/habit-management/question/QuestionHabitPage'
import NewLetterPage from 'src/pages/new-letter/NewLetter'
import MembershipPage from 'src/pages/membership-page-management/MembershipPage'
import TermAndPolicyContent from 'src/pages/term-and-policy/TermAndPolicyContent'
import PostManagementScreen from 'src/pages/post-management/PostManagement'
import PostDetailScreen from 'src/pages/post-detail/PostDetailScreen'

import {
  PATH_ARTICLE_MANAGEMENT,
  PATH_CHALLENGE_DETAIL,
  PATH_CHALLENGE_DETAIL_CREATE,
  PATH_CHALLENGE_DETAIL_MISSION,
  PATH_CHALLENGE_DETAIL_NEW_MISSION,
  PATH_CHALLENGE_DETAIL_NEW_MISSION_PAGE,
  PATH_CHANGE_PASSWORD,
  PATH_CONTACT,
  PATH_DASHBOARD,
  PATH_EDITOR,
  // PATH_EDIT_USER,
  PATH_FORGOT_PASSWORD,
  PATH_INIT_PASSWORD_ADMIN,
  PATH_LOGIN,
  PATH_PACKAGE_MANAGEMENT,
  PATH_RESET_PASSWORD,
  PATH_STAFF_AND_ROLE_MANAGEMENT,
  PATH_SUB_HABIT_CHALLENGE,
  PATH_SUB_HABIT_GOAL,
  PATH_SUB_HABIT_QUESTION,
  PATH_USER_DETAIL,
  PATH_USER_MANAGEMENT,
  PATH_USER_PROFILE,
  PATH_CATEGORY_MANAGEMENT,
  PATH_NEW_LETTER,
  PATH_BLOG_NEWS,
  PATH_ADD_BLOG_NEWS,
  PATH_DETAIL_BLOG_NEWS,
  PATH_ABOUT_US_PAGE_MANAGEMENT,
  PATH_CATEGORY,
  PATH_SUB_CATEGORY,
  PATH_SUB_MENU_HOME_PAGE,
  PATH_SUB_MENU_ABOUT_US_PAGE,
  PATH_SUB_MENU_TERM_POLICIES_PAGE,
  PATH_SUB_MENU_BLOG_PAGE,
  PATH_SUB_GALLERY_PAGE,
  PATH_SUB_PACKAGE_PAGE,
  PATH_POST_MANAGEMENT,
  PATH_POST_CREATE,
} from 'src/routes/navigation'
import PostCreateScreen from 'src/pages/post-create/PostCreateScreen'
import GroupUserPage from 'src/pages/group-user-management/GroupUserPage'
import CreateEditGroupPage from 'src/pages/group-user-management/CreateEditGroupPage'
import EmailContentPage from 'src/pages/email-content-management/EmailContentPage'
import CreateEditEmailContentPage from 'src/pages/email-content-management/CreateEditEmailContentPage'
import RoutineManagement from 'src/pages/habit-management/routine/RoutineManagement'
import RoutinePage from 'src/pages/habit-management/routine/RoutinePage'
import JobPage from '../pages/habit-management/jobs/JobPage'
import JobDetail from '../pages/habit-management/jobs/JobDetail'
import SetupStartingScreen from '../pages/habit-management/starting/SetupStartingScreen'
import { HomepageContentManagement } from 'src/pages/home-page-management'

export const routes: Array<IRoute> = [
  { path: PATH_RESET_PASSWORD, element: ResetPasswordPage },
  { path: PATH_CHANGE_PASSWORD, element: ChangePasswordPage },
  { path: PATH_USER_PROFILE, element: ProfilePage },
  // { path: PATH_DETAIL_PLAN, element: PlanDetail },
  // { path: PATH_PLAN, element: PlanManagement },
  { path: PATH_DASHBOARD, element: Dashboard },
  // { path: PATH_USER_MANAGEMENT, element: UserManagementPage },
  // { path: PATH_EDIT_USER, element: UserDetailPage },
  { path: PATH_USER_DETAIL, element: UserDetailPage },
  // { path: PATH_NOTIFICATION, element: NotificationPage },
  // { path: PATH_EDIT_NOTIFICATION, element: NotificationEditPage },
  // { path: PATH_CHAT_CHANNEL, element: ChatChannelPage },
  { path: PATH_USER_MANAGEMENT, element: UserManagementPage },
  // { path: PATH_MANAGE_ROLE, element: DeclarationPage },
  { path: PATH_ARTICLE_MANAGEMENT, element: DeclarationPage },
  // { path: PATH_DECLARATION_MANAGEMENT, element: DeclarationPage },
  { path: PATH_EDITOR, element: EditorPage },
  { path: PATH_STAFF_AND_ROLE_MANAGEMENT, element: StaffAndRoleManagementPage },
  { path: PATH_PACKAGE_MANAGEMENT, element: PackageManagementPage },
  { path: PATH_NEW_LETTER, element: NewLetterPage },
  { path: PATH_CONTACT, element: ContactPage },
  { path: PATH_BLOG_NEWS, element: BlogNewsPage },
  { path: PATH_ADD_BLOG_NEWS, element: SingleBlog },
  { path: PATH_DETAIL_BLOG_NEWS, element: SingleBlog },
  { path: PATH_SUB_MENU_ABOUT_US_PAGE, element: AboutUsPageManagement },
  { path: PATH_CATEGORY, element: Category },
  { path: PATH_SUB_CATEGORY, element: SubCategory },
  { path: PATH_SUB_MENU_HOME_PAGE, element: DeclarationPage },
  { path: PATH_SUB_MENU_ABOUT_US_PAGE, element: SubCategory },
  { path: PATH_SUB_MENU_TERM_POLICIES_PAGE, element: TermAndPolicyContent },
  { path: PATH_SUB_MENU_BLOG_PAGE, element: BlogContent },
  { path: PATH_SUB_GALLERY_PAGE, element: GalleryScreen },
  { path: PATH_SUB_PACKAGE_PAGE, element: MembershipPage },
  { path: PATH_SUB_PACKAGE_PAGE, element: MembershipPage },
  { path: PATH_HOME_PAGE_MANAGEMENT, element: HomepageContentManagement },

  { path: PATH_SUB_HABIT_GOAL, element: TagHabitPage },
  { path: PATH_SUB_HABIT_QUESTION, element: QuestionHabitPage },
  { path: PATH_SUB_HABIT_QUESTION_DETAIL, element: QuestionDetailPage },
  { path: PATH_SUB_HABIT_CHALLENGE, element: ChallengeHabitPage },
  { path: PATH_SUB_DAILY_ROUTINE, element: RoutineManagement },
  { path: PATH_DAILY_ROUTINE_DETAIL, element: RoutinePage },
  { path: PATH_DAILY_ROUTINE_DETAIL_CREATE, element: RoutinePage },
  { path: PATH_CHALLENGE_DETAIL, element: DetailChallenge },
  { path: PATH_CHALLENGE_DETAIL_MISSION, element: MissionPage },
  { path: PATH_CHALLENGE_DETAIL_NEW_MISSION, element: DetailMission },
  { path: PATH_CHALLENGE_DETAIL_CREATE, element: DetailChallenge },
  { path: PATH_MISSION_DETAIL_CREATE, element: DetailMission },
  { path: PATH_DAILY_ROUTINE_JOBS, element: JobPage },
  { path: PATH_SUB_SETUP_DAILY_ROUTINE, element: SetupStartingScreen },

  { path: PATH_CHALLENGE_DETAIL_NEW_MISSION_PAGE, element: MissionPage },
  { path: PATH_POST_MANAGEMENT, element: PostManagementScreen },
  {
    path: `${PATH_POST_MANAGEMENT}/post-detail/:postId`,
    element: PostDetailScreen,
  },
  {
    path: `${PATH_POST_CREATE}`,
    element: PostCreateScreen,
  },
  {
    path: `${PATH_POST_CREATE}/:postId`,
    element: PostCreateScreen,
  },
  {
    path: `${PATH_EDIT_POST}`,
    element: PostCreateScreen,
  },
  { path: PATH_CHALLENGE_DETAIL_V2, element: DetailChallenge_V2 },
  {
    path: PATH_CHALLENGE_DETAIL_PARTICIPANTS,
    element: DetailChallengeParticipant,
  },

  { path: PATH_GROUP_MANAGEMENT, element: GroupUserPage },
  { path: PATH_GROUP_CREATE, element: CreateEditGroupPage },
  { path: PATH_GROUP_DETAIL, element: CreateEditGroupPage },

  { path: PATH_EMAIL_MANAGEMENT, element: EmailContentPage },
  { path: PATH_EMAIL_CREATE, element: CreateEditEmailContentPage },
  { path: PATH_EMAIL_DETAIL, element: CreateEditEmailContentPage },
  { path: PATH_SUB_MEMBERSHIP_PAGE, element: MembershipPage },
  { path: PATH_DAILY_ROUTINE_JOBS_DETAIL, element: JobDetail },
  // { path: PATH_SUB_MEMBERSHIP_PAGE, element: MembershipPage },
]

export const publicRoutes: Array<IRoute> = [
  //auth
]

export const restrictedRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, element: LoginPage },
  { path: PATH_FORGOT_PASSWORD, element: ForgotPasswordPage },
  { path: PATH_INIT_PASSWORD_ADMIN, element: InitPasswordAdminPage },
]
