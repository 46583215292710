import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import { AuthRoute, PublicRoute } from '@components'
import { LLC_ACCESS_TOKEN, PATH_CHAT_CHANNEL } from '@configs'
import { IRoute } from '@interfaces'
import { AppLayout, DefaultLayout } from '@layouts'
import { authActions } from '@redux'
import ChatLayout from 'src/layouts/ChatLayout'
import ChatContent from 'src/pages/chat/ChatContent'
import { PageError404 } from '../pages'
import { publicRoutes, restrictedRoutes, routes } from './routes'
import ChatWelcome from 'src/components/chatChannel/ChatWelcome'

const Router = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const accessToken = Cookies.get(LLC_ACCESS_TOKEN)
    accessToken &&
      dispatch(authActions.setAccessToken({ access_token: accessToken }))
  }, [dispatch])

  return (
    <Routes>
      <Route
        path={'/*'}
        element={
          <DefaultLayout>
            <PageError404 />
          </DefaultLayout>
        }
      />
      {/* Auth Route */}
      <Route element={<AuthRoute />}>
        {routes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <AppLayout>
                  <Element />
                </AppLayout>
              }
            />
          )
        })}
        <Route
          path={PATH_CHAT_CHANNEL}
          element={
            <AppLayout>
              <ChatLayout>
                <ChatWelcome />
              </ChatLayout>
            </AppLayout>
          }
        />
        <Route
          path={`${PATH_CHAT_CHANNEL}/:chatId`}
          element={
            <AppLayout>
              <ChatLayout>
                <ChatContent />
              </ChatLayout>
            </AppLayout>
          }
        />
      </Route>
      {/* Restricted Route */}
      <Route element={<PublicRoute restricted={true} />}>
        {restrictedRoutes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout>
                  <Element />
                </DefaultLayout>
              }
            />
          )
        })}
      </Route>
      {/* public Route */}
      <Route element={<PublicRoute />}>
        {publicRoutes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout>
                  <Element />
                </DefaultLayout>
              }
            />
          )
        })}
      </Route>
    </Routes>
  )
}

export default Router
