import { Divider } from 'antd'
import React, { Fragment } from 'react'
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form'
import { Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'

interface IContentTabProps {
  activeTab?: string
  control: Control<any>
  getValues?: UseFormGetValues<any>
  setValue?: UseFormSetValue<any>
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>
  slug?: string
  isShowDivider?: boolean
  isShowSelectMedia?: boolean
  category?: EMediaCategory
}

const MetaTab = ({
  activeTab,
  control,
  getValues,
  setValue,
  setIsUploading,
  slug,
  isShowDivider,
  isShowSelectMedia,
  category,
}: IContentTabProps) => {
  const [showModalSelectMedia, setShowModalSelectMedia] = React.useState(false)
  const onChangeMetaRef = React.useRef<any>(null)

  const onClickSelectMedia = () => {
    setShowModalSelectMedia(true)
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (setValue) {
      setValue('metaImage', data?.baseUrl ?? '')
    }
    onChangeMetaRef.current(data?.baseUrl ?? '')

    setShowModalSelectMedia(false)
  }

  return (
    <div className="p-5 bg-white flex flex-col justify-center gap-5">
      {slug ? (
        <div className="wrapper-input-meta">
          <Controller
            name="urlSlug"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Slug:"
                  value={value}
                  onChange={onChange}
                  errors={error?.message}
                  placeholder="Slug will be generated automatically from title"
                />
              )
            }}
          />
        </div>
      ) : (
        <Fragment></Fragment>
      )}
      {slug || isShowDivider ? (
        <div className="wrapper-divider-title flex items-center">
          <div className="font-bold">SEO config</div>
          <div className="flex-1">
            <Divider />
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* TITLE */}
      <div className="wrapper-input-meta">
        <Controller
          name="metaTitle"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Meta title:"
                value={value}
                onChange={(event) => {
                  onChange(event?.target?.value)
                }}
                errors={error?.message}
                required
              />
            )
          }}
        />
      </div>
      {/* DESCRIPTION */}
      <div className="wrapper-input-meta">
        <Controller
          name="metaDescription"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Meta description:"
                value={value}
                onChange={onChange}
                errors={error?.message}
                required
              />
            )
          }}
        />
      </div>
      {/* KEY WORDS */}
      <div className="wrapper-input-meta">
        <Controller
          name="keywords"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Key words:"
                value={value}
                onChange={onChange}
                errors={error?.message}
                required
              />
            )
          }}
        />
      </div>
      {/* Canonical */}
      <div className="wrapper-input-meta">
        <Controller
          name="canonical"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Canonical:"
                value={value}
                onChange={onChange}
                errors={error?.message}
                required
              />
            )
          }}
        />
      </div>

      {/* Schema markup */}
      {/* <div className="wrapper-input-meta">
        <Controller
          name="schemaMarkup"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Schema markup:"
                value={value}
                onChange={onChange}
                errors={error?.message}
                required
              />
            )
          }}
        />
      </div> */}

      {/* IMAGE */}
      <div className="input-image-wrapper">
        <Controller
          name="metaImage"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeMetaRef.current = onChange
            return (
              <CustomDragger
                label="Meta image"
                containerClassName="mt-3"
                required
                onLoadEnd={(data) => {
                  if (setValue) {
                    setValue('metaImage', data?.original ?? '')
                  }
                  onChange(data?.original ?? '')
                }}
                errors={error?.message}
                initResource={value}
                reset={getValues && getValues('metaImage') === ''}
                changeLoading={setIsUploading}
                limitFileSize={5}
                allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                uploadType={EMediaType.IMAGE as any}
                uploadCategory={category}
              />
            )
          }}
        />

        {isShowSelectMedia ? (
          <>
            <SelectMedia onClickSelectMedia={onClickSelectMedia} />
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="wrapper-input-meta hidden">
        <Controller
          name="metaImage"
          defaultValue=""
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Meta image:"
                value={getValues ? getValues('metaImage') : ''}
                // onChange={onChange}
                // errors={error?.message}
                placeholder="Insert meta image url by uploading image"
                disabled={true}
              />
            )
          }}
        />
      </div>

      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={category}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default MetaTab
