import { LoadingOutlined } from '@ant-design/icons'
import { EUploadFileType } from '@configs'
import { UploadProps, message } from 'antd'
import { ChangeEvent, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { IUploadMediaResponse } from 'src/interfaces/media'
import { SimpleImage } from '../image/SimpleImage'
import { Text } from '../typography'
import { galleryAPI } from 'src/api/gallery'
import { EMediaCategory } from 'src/interfaces/gallery'
import { useMediaQuery } from '@utils'

export const CustomDragger = (
  props: UploadProps & {
    containerClassName?: string
    label?: string
    placeholder?: string
    required?: boolean
    onLoadEnd?: (data: IUploadMediaResponse) => void
    errors?: string
    initResource?: string
    reset?: boolean
    changeLoading?: (loading: boolean) => void
    allowFileTypes?: string[]
    limitFileSize?: number
    uploadType?: EUploadFileType
    uploadCategory?: EMediaCategory
    labelClassName?: string
    alignment?: 'row' | 'col'
    note?: string
  }
) => {
  const {
    containerClassName,
    required,
    label,
    placeholder = 'Click to upload media file',
    onLoadEnd,
    initResource,
    errors,
    reset,
    changeLoading,
    allowFileTypes = ['image/png', 'image/jpeg', 'image/jpg'],
    limitFileSize = 20,
    uploadType = EUploadFileType.IMAGE,
    uploadCategory,
    name = 'avatar',
    id = 'avatar',
    labelClassName,
    alignment = 'row',
    disabled,
    note,
    ...restProps
  } = props
  const [loading, setLoading] = useState(false)
  const [resource, setResource] = useState(initResource)
  const isSMScreen = useMediaQuery(`(max-width:640px)`)

  let inputContainerClass = 'grid grid-cols-7 gap-3'
  let labelClass = 'text-right mb-0 '
  let localAlignment = isSMScreen ? 'col' : alignment

  if (localAlignment === 'col') {
    inputContainerClass = 'flex items-start flex-col '
    labelClass = 'text-left mb-2 '
  }

  const handleChangeMedia = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    changeLoading?.(true)
    if (!e.target.files || e.target.files.length === 0) {
      setLoading(false)
      changeLoading?.(false)
      return
    }

    const file = e.target.files[0]
    const isMatchMediaType = allowFileTypes.includes(file.type)

    const allowedInputType = allowFileTypes
      ?.map((item, index) => item.split('/')[1])
      ?.join('/')
      ?.toUpperCase()

    if (!isMatchMediaType) {
      message.error(`You can only upload ${allowedInputType}file!`)
      setLoading(false)
      changeLoading?.(false)
      return
    }

    const isMatchMediaSize = Number(file.size) / 1024 / 1024 < limitFileSize
    if (!isMatchMediaSize) {
      message.error(`Media file must smaller than ${limitFileSize}MB!`)
      setLoading(false)
      changeLoading?.(false)
      return
    }

    try {
      if (uploadType === EUploadFileType.IMAGE) {
        const response = await galleryAPI.uploadImage(file, uploadCategory)

        if (response.success) {
          message.success(response?.message ?? 'Upload image successfully!')
          setResource(response?.data?.original)
          if (onLoadEnd) {
            onLoadEnd(response?.data)
          }
          setLoading(false)
          changeLoading?.(false)
        }
      } else {
        const response = await galleryAPI.uploadVideo(file, uploadCategory)

        if (response.success) {
          message.success(response?.message ?? 'Upload image successfully!')

          setResource(response?.data?.url)
          if (onLoadEnd) {
            onLoadEnd(response?.data)
          }
          setLoading(false)
          changeLoading?.(false)
        }
      }
    } catch (error: any) {
      console.log(error)
      message.error(error?.message ?? 'Upload file failed!')
    }
  }

  useEffect(() => {
    setResource(initResource)
  }, [reset, initResource, uploadType])

  return (
    <>
      <div
        className={`Input w-full ${inputContainerClass} ${
          containerClassName || ''
        }`}
      >
        {label && (
          <label
            htmlFor={label}
            className={`Input__label ${labelClass} inline-flex mr-[1.25rem] capitalize  text-dark col-span-2 `}
          >
            {label}
            {required && (
              <span className="required text-[#B91C1C] font-bold"> *</span>
            )}
          </label>
        )}

        <div
          className={`Input__field-container ${labelClassName} relative ${
            label ? 'col-span-5' : 'col-span-7'
          }`}
        >
          <label
            className={`icon-change-avatar cursor-pointer border-[1.5px] border-dashed h-[200px] w-full p-5 col-span-5 inline-flex items-center justify-center rounded-lg ${
              disabled ? '' : 'hover:border-primary'
            } `}
            htmlFor={name}
            style={{
              border: errors ? '1px solid #B91C1C' : '',
              borderRadius: errors ? '0.375rem' : '',
            }}
          >
            {loading ? (
              <LoadingOutlined size={32} color={'blue'} spin />
            ) : resource ? (
              uploadType === EUploadFileType.IMAGE ? (
                <SimpleImage source={resource} />
              ) : (
                <div className="w-[200px] h-[120px]">
                  <ReactPlayer
                    url={resource}
                    loop
                    controls={!!resource}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                  />
                </div>
              )
            ) : (
              <Text>Click to upload file!</Text>
            )}
          </label>
          <input
            type="file"
            id={id}
            name={name}
            className="hidden w-3/4"
            accept={allowFileTypes.join(',')}
            onChange={handleChangeMedia}
            disabled={disabled}
          />
        </div>
      </div>
      {errors && (
        <div className="grid grid-cols-7 w-full ">
          {label && localAlignment === 'row' && (
            <div className={labelClass + ' col-span-2 min-w-[1px]'}></div>
          )}
          <div
            style={{
              color: 'rgb(var(--color-danger)',
            }}
            className={`Input__text-error mt-2 text-sm col-span-7 ${
              alignment === 'col' ? 'sm:col-span-7 text-left' : 'sm:col-span-5'
            }`}
          >
            {errors}
          </div>
        </div>
      )}
    </>
  )
}
