import {
  ABOUT_US,
  COMMUNITY_CONTENT,
  CONTACT_PAGE,
  HOME_PAGE_CONTENT,
  HOME_PAGE_VIDEO,
  HOME_SEO,
  MEMBERSHIP,
  QUOTES,
  ROAD_MAP,
  SEO_CONFIG,
} from '@configs'
import { useAppDispatch } from '@redux'
import { Tabs } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useEffect, useState } from 'react'
import { Text } from 'src/common'
import AboutUs from 'src/components/declaration/about-us/AboutUs'
import CommunityPage from 'src/components/declaration/community/CommunityPage'
import { ContactPage } from 'src/components/declaration/contact/ContactPage'
import HomeSeoPage from 'src/components/declaration/home-seo/HomeSeoPage'
import { HomePageVideo } from 'src/components/declaration/homepage-video/HomePageVideoPage'
import MemberShip from 'src/components/declaration/member-ship/MemberShip'
import QuotesPage from 'src/components/declaration/quotes/QuotesPage'
import RoadMapPage from 'src/components/declaration/road-map/RoadMapPage'
import { HomepageContentManagement } from 'src/pages/home-page-management'
import { getHomeSeo } from 'src/redux/actions/content-management'
import styled from 'styled-components'

interface Props {}

// eslint-disable-next-line no-empty-pattern
const DeclarationTabs = ({}: Props) => {
  const [size, setSize] = useState<SizeType>('small')
  const [activeTab, setActiveTab] = useState<string>(HOME_PAGE_VIDEO)

  const dispatch = useAppDispatch()

  const items = [
    {
      label: <Text>{HOME_PAGE_VIDEO}</Text>,
      key: HOME_PAGE_VIDEO,
      children: <HomePageVideo activeTab={activeTab} />,
    },
    // {
    //   label: <Text>{ABOUT_US}</Text>,
    //   key: ABOUT_US,
    //   children: <AboutUs activeTab={activeTab} />,
    // },
    // {
    //   label: <Text>{ROAD_MAP}</Text>,
    //   key: ROAD_MAP,
    //   children: <RoadMapPage activeTab={activeTab} />,
    // },
    // {
    //   label: <Text>{QUOTES}</Text>,
    //   key: QUOTES,
    //   children: <QuotesPage activeTab={activeTab} />,
    // },
    // {
    //   label: <Text>{COMMUNITY_CONTENT}</Text>,
    //   key: COMMUNITY_CONTENT,
    //   children: <CommunityPage activeTab={activeTab} />,
    // },
    // {
    //   label: <Text>{MEMBERSHIP}</Text>,
    //   key: MEMBERSHIP,
    //   children: <MemberShip activeTab={activeTab} />,
    // },
    // {
    //   label: <Text>{CONTACT_PAGE}</Text>,
    //   key: CONTACT_PAGE,
    //   children: <ContactPage activeTab={activeTab} />,
    // },

    {
      label: <Text>{'Home page content'}</Text>,
      key: HOME_PAGE_CONTENT,
      children: <HomepageContentManagement activeTab={activeTab} />,
    },
    {
      label: <Text>{SEO_CONFIG}</Text>,
      key: HOME_SEO,
      children: <HomeSeoPage activeTab={activeTab} />,
    },
  ]

  useEffect(() => {
    const getHomeSeoFn = async () => {
      try {
        const response = await dispatch(getHomeSeo()).unwrap()
      } catch (error: any) {
        console.log('error', error)
      }
    }

    getHomeSeoFn()
  }, [])

  return (
    <HomePageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        size={size}
        items={items}
        defaultChecked={true}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </HomePageStyled>
  )
}

export default DeclarationTabs

const HomePageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
