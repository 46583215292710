import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Skeleton, Tooltip, Typography, message } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button, Input, Loading, SharedTable } from 'src/common'
import AddUpdatePackageManagementModal, {
  EPackageBenefits,
  EPackageBenefitsItem,
} from 'src/components/package-management/AddUpdatePackageManagementModal'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import { IPackage, IPackageParams } from 'src/interfaces/package-management'
import {
  RootState,
  packageActions,
  selectPackageLoading,
  useAppDispatch,
} from 'src/redux'
import {
  createPackageAction,
  deletePackageAction,
  getListPackageAction,
  getPackageBenefitsAction,
  getPackageByIdAction,
  updatePackageAction,
} from 'src/redux/actions/package-management'
import { formatCurrencyInput } from 'src/utils/priceFormatter'
import { flatten, uniq, uniqBy } from 'lodash'

export const PackageManagementPage = () => {
  const [open, setOpen] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [isSearching, setIsSearching] = useState(false)
  const debouncedValue = useDebounce<string | null>(searchValue, 800)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(
    null
  )

  const dispatch = useAppDispatch()
  const {
    packages,
    totalPackages,
    pagePackages,
    limitPackages,
    packageBenefits,
    selectedPackage,
  } = useSelector((state: RootState) => state.package)

  const getListPackageActionLoading = useSelector((state: RootState) =>
    selectPackageLoading(state, 'getListPackageAction')
  )

  const createPackageActionLoading = useSelector((state: RootState) =>
    selectPackageLoading(state, 'createPackageAction')
  )

  const onClickDeleteItem = (id: number) => {
    setOpenConfirmDeleteModal(true)
    setSelectedPackageId(id)
  }

  const onCancelDelete = () => {
    setOpenConfirmDeleteModal(false)
    setSelectedPackageId(null)
  }
  const getPackages = async (params?: IPackageParams) => {
    dispatch(
      getListPackageAction({
        page: +pagePackages,
        sort,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        ...(params || {}),
      })
    )
  }

  const getPackagesBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getListPackageAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setIsSearching(false)
    }
  }

  const getPackageById = async (id: number | string) => {
    dispatch(getPackageByIdAction(id))
  }

  const onEditPackage = async (id: number | string) => {
    getPackageById(id)
    setOpen(true)
  }

  const onSelectPackageBenefitId = (id: number) => {
    let localSelectedPackageBenefitId = selectedPackage?.packageBenefitIds || []

    if (selectedPackage?.packageBenefitIds?.includes(id)) {
      localSelectedPackageBenefitId =
        selectedPackage?.packageBenefitIds?.filter(
          (packageId) => packageId !== id
        )
    } else {
      localSelectedPackageBenefitId = [
        ...(selectedPackage?.packageBenefitIds || []),
        id,
      ]
    }

    dispatch(
      packageActions.setSelectedPackage({
        packageBenefitIds: localSelectedPackageBenefitId,
      })
    )
  }

  const onCreatePackage = async (
    data: IPackage & { listSelect?: string[] }
  ) => {
    const parents: any = Object.values(EPackageBenefits)
    const childItems = Object.values(EPackageBenefitsItem)
    const newList: any[] = []

    data?.listSelect?.forEach((slug) => {
      let matchingItem = data.packageBenefitItems?.find(
        (item: any) => item.slug === slug
      )
      if (matchingItem) {
        newList.push(matchingItem)
      } else if (parents.includes(slug)) {
        const listSlugChild = childItems.filter((item) => item.startsWith(slug))
        newList.push(
          listSlugChild.map((_irt) => {
            return {
              slug: _irt,
            }
          })
        )
      } else {
        newList.push({ slug: slug })
      }
    })

    const slugMap = new Map<string, any>()

    // Iterate through the data and add items to the map
    flatten(newList).forEach((item) => {
      const slug = item.slug
      // If the slug is not in the map, add the item
      if (!slugMap.has(slug)) {
        slugMap.set(slug, item)
      } else {
        // If the slug is in the map, merge the properties of the existing item with the new one
        const existingItem = slugMap.get(slug)
        slugMap.set(slug, { ...existingItem, ...item })
      }
    })

    try {
      let response: any
      if (!selectedPackage?.id) {
        response = await dispatch(
          createPackageAction({
            name: data.name,
            nameInDutch: data.nameInDutch,
            priceEur: +(data.priceEur || 0),
            priceUsd: +(data.priceUsd || 0),
            packageBenefitItems: Array.from(slugMap.values()),
            mediaId: data.mediaId,
            backgroundColor: data?.backgroundColor,
            textColor: data?.textColor,
            type: data.type,
          })
        ).unwrap()
      } else {
        response = await dispatch(
          updatePackageAction({
            id: selectedPackage?.id,
            name: data.name,
            nameInDutch: data.nameInDutch,
            priceEur: +(data.priceEur || 0),
            priceUsd: +(data.priceUsd || 0),
            packageBenefitItems: Array.from(slugMap.values()),
            isActive: data.isActive,
            backgroundColor: data?.backgroundColor,
            textColor: data?.textColor,
            type: data.type,
            mediaId: data.mediaId,
          })
        ).unwrap()
      }

      if (response.success) {
        message.success({
          content: response.message,
        })

        getPackages()
        onClose()
      }
    } catch (error: any) {
      if (error) {
        message.error({
          content: error.errors?.length ? error.errors[0] : error.message,
        })
      }
    }
  }

  const onClose = () => {
    setOpen(false)
    dispatch(packageActions.setSelectedPackage())
    dispatch(packageActions.setSelectedPackage())
  }

  const onDeletePackage = async () => {
    try {
      if (selectedPackageId) {
        const response = await dispatch(
          deletePackageAction(selectedPackageId)
        ).unwrap()
        if (response.success) {
          message.success({
            content: response.message,
          })
        }
        getPackages({
          page: packages.length === 1 ? +pagePackages - 1 : +pagePackages,
        })
      }
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setOpenConfirmDeleteModal(false)
    }
  }

  const columns = [
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Name</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({ ...sort, name: sort.name === 'asc' ? 'desc' : 'asc' })
                getPackages({
                  sort: { ...sort, name: sort.name === 'asc' ? 'desc' : 'asc' },
                })
              }}
            >
              {sort.name === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'name',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center max-w-[460px]">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'nowrap',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
          </div>
        )
      },
    },
    {
      width: '12.5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Price (EUR)</p>
          </div>
        )
      },
      dataIndex: 'priceEur',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center">
            {e === null ? 0 : formatCurrencyInput(`${e}`)}€
          </div>
        )
      },
    },
    {
      width: '12.5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Price (USD)</p>
          </div>
        )
      },
      dataIndex: 'priceUsd',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center">
            {e === null ? 0 : formatCurrencyInput(`${e}`)}$
          </div>
        )
      },
    },
    {
      width: '25%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Create time</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  ...sort,
                  createdAt: sort.createdAt === 'asc' ? 'desc' : 'asc',
                })
                getPackages({
                  sort: {
                    ...sort,
                    createdAt: sort.createdAt === 'asc' ? 'desc' : 'asc',
                  },
                })
              }}
            >
              {sort.createdAt === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'createdAt',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center">
            {moment(e).format('HH:mm:ss - DD MMM YYYY')}
          </div>
        )
      },
    },

    {
      width: '10%',
      title: 'Privacy',
      dataIndex: 'type',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-start capitalize">{e}</div>
        )
      },
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Status</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  ...sort,
                  isActive: sort.isActive === 'asc' ? 'desc' : 'asc',
                })
                getPackages({
                  sort: {
                    ...sort,
                    isActive: sort.isActive === 'asc' ? 'desc' : 'asc',
                  },
                })
              }}
            >
              {sort.isActive === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'isActive',
      key: 'id',
      render: (e: boolean) => {
        return (
          <div className="flex items-center justify-center  capitalize">
            {e ? 'Active' : 'Inactive'}
          </div>
        )
      },
    },
    {
      width: '15%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">Action</p>
          </div>
        )
      },
      key: 'id',
      dataIndex: ['id'],
      render: (id: number, record: IPackage, index: number) => (
        <div className="flex space-x-4 items-center justify-center">
          <EditOutlined
            className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
            onClick={() => {
              onEditPackage(id)
            }}
          />
          <Tooltip
            title={
              record.isDefault
                ? 'This is a default package, you can not delete this package'
                : 'This package will be deleted'
            }
          >
            <DeleteOutlined
              disabled={record.isDefault}
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 ${
                record.isDefault ? '!text-gray-600' : '!text-red-600'
              }`}
              onClick={() => {
                !record.isDefault && onClickDeleteItem(id)
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getPackages({ page: 1 })
  }, [])

  useEffect(() => {
    dispatch(getPackageBenefitsAction())
  }, [dispatch])

  useEffect(() => {
    debouncedValue !== null && getPackagesBySearchValue()
  }, [debouncedValue])

  return (
    <PackageManagementStyled>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[500px] flex-1">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Enter package name or package price"
            value={searchValue === null ? '' : searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            suffix={isSearching ? <Loading /> : undefined}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true)
            }}
          >
            Add new
          </Button>
        </div>
      </div>
      <div className="pb-[1.25rem]">
        {getListPackageActionLoading || isSearching ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <SharedTable
            columns={columns as any}
            dataSource={packages}
            paginationProps={{
              total: !!totalPackages ? +totalPackages : undefined,
              pageSize: +limitPackages || 10,
              current: +pagePackages,
              onChange(page) {
                getPackages({
                  page,
                })
              },
            }}
          />
        )}
      </div>

      {open && (
        <AddUpdatePackageManagementModal
          packageBenefits={packageBenefits}
          selectedPackage={selectedPackage}
          open={open}
          isLoading={!!createPackageActionLoading}
          onClose={onClose}
          onSubmit={onCreatePackage}
          onSelectPackageId={onSelectPackageBenefitId}
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete Package"
          content="Are you sure to delete this package?"
          open={openConfirmDeleteModal}
          isLoading={false}
          onDelete={onDeletePackage}
          onClose={onCancelDelete}
        />
      )}
    </PackageManagementStyled>
  )
}

const PackageManagementStyled = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})
