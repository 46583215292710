import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import {
  IAddCEOQuotesRequest,
  ICommonContentDetail,
} from 'src/interfaces/aboutus-management'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { z } from 'zod'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'

type CEOquotesDetailModalProps = {
  open: boolean
  onClose: () => void
  isLoading: boolean
  title?: string
  content?: ReactNode
  onSave: (data: IAddCEOQuotesRequest) => void
  item?: ICommonContentDetail | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}

const addAboutUsSchema = z.object({
  name: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:aboutsus_CEOquotes_Author_length_error') as string,
    }),
  no: z
    .number()
    .min(1, { message: t('error:aboutsus_providing_no_error') as string }),
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:aboutsus_CEOquotes_role_length_error') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:aboutsus_CEOquotes_role_length_error') as string,
    }),
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(300, {
      message: t('error:aboutsus_CEOquotes_quote_length_error') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(300, {
      message: t('error:aboutsus_CEOquotes_quote_length_error') as string,
    }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaMobileId: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
})

export const CEOquotesValueDetailModal = (props: CEOquotesDetailModalProps) => {
  const {
    onSave,
    isLoading,
    item,
    changeLoading,
    open,
    onClose,

    noDefault,
  } = props

  const [imageSrc, setImageSrc] = useState(item?.media?.url ?? '')
  const [imageMobileSrc, setImageMobileSrc] = useState(
    item?.mediaMobile?.url ?? ''
  )
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const defaultValue: IAddCEOQuotesRequest = {
    content: item?.content ?? '',
    contentInDutch: item?.contentInDutch ?? '',
    name: item?.name ?? '',
    mediaId: item?.mediaId ?? 0,
    mediaMobileId: item?.mediaMobileId ?? 0,
    title: item?.title ?? '',
    titleInDutch: item?.titleInDutch ?? '',
    no: item?.no ?? noDefault,
  }
  const { control, handleSubmit, setValue } = useForm<IAddCEOQuotesRequest>({
    defaultValues: defaultValue,
    resolver: zodResolver(addAboutUsSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  useEffect(() => {
    if (item) {
      setValue('name', item?.name ?? '')
      setValue('content', item?.content ?? '')
      setValue('contentInDutch', item?.contentInDutch ?? '')
      setValue('mediaId', item?.mediaId ?? 0)
      setValue('mediaMobileId', item?.mediaMobileId ?? 0)
      setValue('title', item?.title ?? '')
      setValue('titleInDutch', item?.titleInDutch ?? '')
      setValue('no', item?.no ?? noDefault)
      item.media?.url && setImageSrc(item.media?.url)
      item.mediaMobile?.url && setImageMobileSrc(item.mediaMobile?.url)
    }
  }, [item])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {item ? 'CEO quote detail' : 'Add CEO quote'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <form>
          <Controller
            name="no"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="No"
                  name="no"
                  required
                  min="0"
                  onChange={(e) => {
                    onChange(parseInt(e.target.value || '0', 10))
                  }}
                  type="number"
                  value={value?.toString()}
                  inputMode={'numeric'}
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name={'name'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Author"
                  name="author"
                  required
                  onChange={onChange}
                  value={value}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                />
              )
            }}
          />

          <Controller
            name={'title'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Author title"
                  name="title"
                  required
                  onChange={onChange}
                  value={value}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                />
              )
            }}
          />

          <Controller
            name={'titleInDutch'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Author title in Netherlands"
                  name="titleInDutch"
                  required
                  onChange={onChange}
                  value={value}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                />
              )
            }}
          />

          <Controller
            name="mediaId"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              onChangeDesktopRef.current = onChange
              return (
                <CustomDragger
                  label="Image"
                  name="mediaId"
                  id="mediaId"
                  containerClassName="mt-3"
                  required
                  onLoadEnd={(data) => {
                    setValue('mediaId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                  }}
                  errors={error?.message}
                  initResource={imageSrc}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={changeLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.ABOUT_US}
                />
              )
            }}
          />
          <SelectMedia
            onClickSelectMedia={() => {
              setSelectedField((pre) => 'desktop')
              setShowModalSelectMedia(true)
            }}
          />

          <Controller
            name="mediaMobileId"
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              onChangeMobileRef.current = onChange
              return (
                <CustomDragger
                  label="Image mobile"
                  name="mediaMobileId"
                  id="mediaMobileId"
                  containerClassName="mt-3"
                  required
                  onLoadEnd={(data) => {
                    setValue('mediaMobileId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                  }}
                  errors={error?.message}
                  initResource={imageMobileSrc}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={changeLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.ABOUT_US}
                />
              )
            }}
          />
          <SelectMedia
            onClickSelectMedia={() => {
              setSelectedField((pre) => 'mobile')
              setShowModalSelectMedia(true)
            }}
          />

          <Controller
            name="content"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  label="Quote"
                  name="quote"
                  required
                  onChange={onChange}
                  value={value}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                  isGrid
                  containerClassName="mt-3"
                />
              )
            }}
          />

          <Controller
            name="contentInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextArea
                  label="Quote in Netherlands"
                  name="quoteInDutch"
                  required
                  onChange={onChange}
                  value={value}
                  style={{ marginTop: 10 }}
                  errors={error?.message}
                  isGrid
                  containerClassName="mt-3"
                />
              )
            }}
          />

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-end">
              <Button
                htmlType="submit"
                type="primary"
                size="middle"
                className="submit__btn login-btn"
                loading={isLoading}
                onClick={handleSubmit(onSave)}
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.ABOUT_US}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </AppModal>
  )
}
