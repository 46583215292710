/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { uniq } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { EPackagePrivacy, WHITE_COLOR } from 'src/configs'
import {
  IPackage,
  IPackageBenefitsModule,
} from 'src/interfaces/package-management'
import { formatCurrencyInput } from 'src/utils/priceFormatter'
import * as z from 'zod'
import CheckListItem from './CheckListItem'
import CustomInput from './components/CustomInput'
import { CustomDragger } from '../../common/upload/CustomDragger'
import {
  EMediaCategory,
  EMediaType,
  IMediaItem,
} from '../../interfaces/gallery'
import SelectMedia from '../gallery/SelectMedia'
import SelectMediaModal from '../gallery/SelectMediaModal'

interface IAddUpdatePackageManagementModalProps {
  selectedPackage?: IPackage
  packageBenefits: IPackageBenefitsModule[]
  open: boolean
  isLoading: boolean
  onSelectPackageId?: (id: number) => void
  onClose?: () => void
  onSubmit: (data: IPackage & Record<any, any>) => Promise<void>
}
export enum EPackageBenefits {
  REACH_OUR_BLOG = 'reach_our_blog',
  COMMUNITY = 'community',
  PRODUCT_DISCOUNT = 'product_discount',
  LESSON = 'lesson',
  BOOK_RETREAT = 'book_retreat',
  BOOK_WORKSHOP = 'book_workshop',
  BOOK_FESTIVAL_TICKET = 'book_festival_ticket',
  BIRTHDAY_PRESENT = 'birthday_present',
}
export enum EPackageBenefitsItem {
  // REACH_OUR_BLOG
  REACH_OUR_BLOG = 'reach_our_blog',
  // COMMUNITY
  COMMUNITY_FREE_TRY_APP = 'community_free_try_app',
  COMMUNITY_FULL_ACCESS = 'community_full_access',
  // PRODUCT_DISCOUNT
  PRODUCT_DISCOUNT_PERCENTS = 'product_discount_percents',
  // LESSON
  LESSON_FREE = 'lesson_free',
  LESSON_FREE_PREMIUM = 'lesson_free_premium',
  LESSON_FREE_ALL = 'lesson_free_all',
  // BOOK_RETREAT
  BOOK_RETREAT_FREE_UPGRADE_ROOM = 'book_retreat_free_upgrade_room',
  BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES = 'book_retreat_discount_all_activities',
  BOOK_RETREAT_DISCOUNT_ALL_ROOMS = 'book_retreat_discount_all_rooms',
  BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS = 'book_retreat_additional_free_nights',
  BOOK_RETREAT_LIMITED_TO_VISIT = 'book_retreat_limited_to_visit',
  BOOK_RETREAT_FREE_STAY_AND_ACTIVITIES = 'book_retreat_free_stay_and_actives',
  // BOOK_WORKSHOP
  BOOK_WORKSHOP_DISCOUNT_PERCENTS = 'book_workshop_discount_percents',
  BOOK_WORKSHOP_FREE = 'book_workshop_free',
  // BOOK_FESTIVAL_TICKET
  BOOK_FESTIVAL_TICKET_EARLY_BIRD = 'book_festival_ticket_early_bird',
  BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS = 'book_festival_ticket_discount_percents',
  BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS = 'book_festival_ticket_free_vip_access',
  // BIRTHDAY_PRESENT
  BIRTHDAY_PRESENT = 'birthday_present',
}

const packageSchema = z.object({
  name: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:name_package_length_error'),
    })
    .min(2, {
      message: t('error:name_package_length_error'),
    }),
  nameInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:name_package_length_error'),
    })
    .min(2, {
      message: t('error:name_package_length_error'),
    }),
  priceUsd: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .regex(/^\d+(\.\d{1,4})?$/, {
      message: t('error:price_format_error') as string,
    })
    .max(13, {
      message: t('error:price_length_error') as string,
    }),
  isActive: z.boolean(),
  type: z.boolean(),
  priceEur: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .regex(/^\d+(\.\d{1,4})?$/, {
      message: t('error:price_format_error') as string,
    })
    .max(13, {
      message: t('error:price_length_error') as string,
    }),
  textColor: z.string(),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
})

interface ValidationErrors {
  [key: string]: string | null
}
interface InputValues {
  [key: string]:
    | string
    | {
        discount: number | string
      }
}
const parents: any = Object.values(EPackageBenefits)
const childBenifit: any = Object.values(EPackageBenefitsItem)
const AddUpdatePackageManagementModal = (
  props: IAddUpdatePackageManagementModalProps
) => {
  const { open, selectedPackage, isLoading, onClose, onSubmit } = props

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      nameInDutch: '',
      priceUsd: '',
      priceEur: '',
      isActive: true,
      textColor: WHITE_COLOR,
      type: true,
      packageConfig: [],
      mediaId: 0,
    },
    resolver: zodResolver(packageSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const isEdit = !!selectedPackage?.id
  const [inputValues, setInputValues] = useState<InputValues[] | any>([])
  const [checkedList, setCheckedList] = useState<
    EPackageBenefitsItem[] & EPackageBenefits[]
  >([])

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const isDefault = selectedPackage?.isDefault

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const [imageSrc, setImageSrc] = useState('')

  const handleChangeInput = (key: EPackageBenefitsItem, value?: string) => {
    const pass = !value
      ? {
          slug: key,
        }
      : validateRules(key, value)
    if (pass) {
      const outputValue = !value
        ? {
            slug: key,
          }
        : checkOutput(key, value)
      if (inputValues.filter((item: any) => item.slug === key).length) {
        setInputValues((data: any) => {
          const newData = data.filter((item: any) => item.slug !== key)
          return [
            ...newData,
            {
              ...outputValue,
            },
          ]
        })
      } else {
        setInputValues([
          ...inputValues,
          {
            ...outputValue,
          },
        ])
      }
    }
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const validateRules = (key: EPackageBenefitsItem, value: string) => {
    switch (key) {
      case EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS:
      case EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES:
      case EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS:
      case EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS:
      case EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS:
        const validateDiscount = z
          .number()
          .min(1, { message: 'Please enter a value from 1 to 100' })
          .max(100, { message: 'Please enter a value from 1 to 100' })
        if (!validateDiscount.safeParse(+value).success) {
          setValidationErrors({
            ...validationErrors,
            [key]: 'Please enter a value from 1 to 100',
          })
          return false
        } else {
          setValidationErrors((validationErrors) => {
            delete validationErrors[key]
            return validationErrors
          })
          return true
        }
      case EPackageBenefitsItem.BIRTHDAY_PRESENT:
        const birthday = z
          .string()
          .min(0, {
            message: 'Birthday present must be between 0 and 255 characters',
          })
          .max(255, {
            message: 'Birthday present must be between 0 and 255 characters',
          })
        if (!birthday.safeParse(value).success) {
          setValidationErrors({
            ...validationErrors,
            [key]: 'Birthday present must be between 0 and 255 characters',
          })
          return false
        } else {
          setValidationErrors((validationErrors) => {
            delete validationErrors[key]
            return validationErrors
          })
          return true
        }
      default:
        const validatePercentage = z
          .number()
          .min(1, { message: 'Please enter a value greater than 0' })
        if (!validatePercentage.safeParse(+value).success) {
          setValidationErrors({
            ...validationErrors,
            [key]: 'Please enter a value greater than 0',
          })
          return false
        } else {
          setValidationErrors((validationErrors) => {
            delete validationErrors[key]
            return validationErrors
          })
          return true
        }
        break
    }
  }
  const treeDataTwo = [
    {
      title: 'Reach our blog',
      key: EPackageBenefits.REACH_OUR_BLOG,
      checked: true,
    },
    {
      title: 'Community',
      key: EPackageBenefits.COMMUNITY,
      single: true,
      children: [
        {
          title: (
            <CustomInput
              title="Free try apps"
              suffix="days"
              error={
                validationErrors[EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP]
              }
              onClick={() => {
                if (
                  !checkedList.includes(
                    EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP
                  )
                ) {
                  onCheck(
                    EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP,
                    EPackageBenefits.COMMUNITY
                  )
                }
              }}
              disabled={!checkedList.includes(EPackageBenefits.COMMUNITY)}
              keyNote={EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP}
              inputValues={inputValues}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP,
                  e.target.value
                )
              }}
            />
          ),
          key: EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP,
          disabled: !checkedList.includes(EPackageBenefits.COMMUNITY),
        },
        {
          title: 'Full access',
          key: EPackageBenefitsItem.COMMUNITY_FULL_ACCESS,
          disabled: !checkedList.includes(EPackageBenefits.COMMUNITY),
        },
      ],
    },
    {
      title: 'Product discount',
      key: EPackageBenefits.PRODUCT_DISCOUNT,
      children: [
        {
          title: (
            <CustomInput
              title="Discount"
              suffix="%"
              error={
                validationErrors[EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS]
              }
              onClick={() => {
                if (
                  !checkedList.includes(
                    EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS
                  )
                ) {
                  onCheck(EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS)
                }
              }}
              disabled={
                !checkedList.includes(EPackageBenefits.PRODUCT_DISCOUNT)
              }
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS,
                  e.target.value
                )
              }}
              onFocus={() => {
                onCheck(
                  EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS,
                  EPackageBenefits.PRODUCT_DISCOUNT
                )
              }}
              keyNote={EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS}
              inputValues={inputValues}
            />
          ),
          key: EPackageBenefitsItem.PRODUCT_DISCOUNT_PERCENTS,
          disabled: !checkedList.includes(EPackageBenefits.PRODUCT_DISCOUNT),
        },
      ],
    },
    {
      title: 'Lesson',
      key: EPackageBenefits.LESSON,
      single: true,
      children: [
        {
          title: 'Free lessons',
          key: EPackageBenefitsItem.LESSON_FREE,
          disabled: !checkedList.includes(EPackageBenefits.LESSON),
        },
        {
          title: (
            <CustomInput
              title="Free premium lesson"
              suffix="days"
              error={validationErrors[EPackageBenefitsItem.LESSON_FREE_PREMIUM]}
              onClick={() => {
                if (
                  !checkedList.includes(
                    EPackageBenefitsItem.LESSON_FREE_PREMIUM
                  )
                ) {
                  onCheck(
                    EPackageBenefitsItem.LESSON_FREE_PREMIUM,
                    EPackageBenefits.LESSON
                  )
                }
              }}
              disabled={!checkedList.includes(EPackageBenefits.LESSON)}
              keyNote={EPackageBenefitsItem.LESSON_FREE_PREMIUM}
              inputValues={inputValues}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.LESSON_FREE_PREMIUM,
                  e.target.value
                )
              }}
            />
          ),
          key: EPackageBenefitsItem.LESSON_FREE_PREMIUM,
          disabled: !checkedList.includes(EPackageBenefits.LESSON),
        },
        {
          title: 'Free all lessons',
          key: EPackageBenefitsItem.LESSON_FREE_ALL,
          disabled: !checkedList.includes(EPackageBenefits.LESSON),
        },
      ],
    },
    {
      title: 'Book retreat',
      key: EPackageBenefits.BOOK_RETREAT,
      hasChildUnchecked: true,
      children: [
        {
          title: 'Free Upgrade room',
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
          key: EPackageBenefitsItem.BOOK_RETREAT_FREE_UPGRADE_ROOM,
        },
        {
          title: (
            <CustomInput
              title="Discount"
              suffix="% all activities"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES
                ]
              }
              onClick={() => {
                onCheck(
                  EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES,
                  EPackageBenefits.BOOK_RETREAT,
                  undefined,
                  true
                )
              }}
              disabled={!checkedList.includes(EPackageBenefits.BOOK_RETREAT)}
              keyNote={
                EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES
              }
              inputValues={inputValues}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES,
                  e.target.value
                )
              }}
            />
          ),
          key: EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ACTIVITIES,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
        },
        {
          title: (
            <CustomInput
              title="Discount"
              suffix="% all rooms"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS
                ]
              }
              onClick={() => {
                onCheck(
                  EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS,
                  EPackageBenefits.BOOK_RETREAT,
                  undefined,
                  true
                )
              }}
              disabled={!checkedList.includes(EPackageBenefits.BOOK_RETREAT)}
              keyNote={EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS}
              inputValues={inputValues}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS,
                  e.target.value
                )
              }}
            />
          ),
          key: EPackageBenefitsItem.BOOK_RETREAT_DISCOUNT_ALL_ROOMS,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
        },
        {
          title: (
            <CustomInput
              title="Get additional free night(s)"
              suffix="% all rooms"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS
                ]
              }
              onClick={() => {
                onCheck(
                  EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS,
                  EPackageBenefits.BOOK_RETREAT,
                  undefined,
                  true
                )
              }}
              keyNote={EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS}
              inputValues={inputValues}
              disabled={!checkedList.includes(EPackageBenefits.BOOK_RETREAT)}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS,
                  e.target.value
                )
              }}
            />
          ),
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
          key: EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS,
        },
        {
          title: (
            <CustomInput
              title="Limited to visit"
              suffix="time"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT
                ]
              }
              disabled={!checkedList.includes(EPackageBenefits.BOOK_RETREAT)}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT,
                  e.target.value
                )
              }}
              onClick={() => {
                onCheck(
                  EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT,
                  EPackageBenefits.BOOK_RETREAT,
                  undefined,
                  true
                )
              }}
              keyNote={EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT}
              inputValues={inputValues}
            />
          ),
          key: EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
        },
        {
          title: 'Free Stay and activities',
          key: EPackageBenefitsItem.BOOK_RETREAT_FREE_STAY_AND_ACTIVITIES,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_RETREAT),
          isUncheckRest: true,
        },
      ],
    },
    {
      title: 'Book workshop',
      key: EPackageBenefits.BOOK_WORKSHOP,
      single: true,

      children: [
        {
          title: (
            <CustomInput
              title="Discount"
              suffix="%"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS
                ]
              }
              onClick={() => {
                if (
                  !checkedList.includes(
                    EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS
                  )
                ) {
                  onCheck(
                    EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS,
                    EPackageBenefits.BOOK_WORKSHOP
                  )
                }
              }}
              disabled={!checkedList.includes(EPackageBenefits.BOOK_WORKSHOP)}
              keyNote={EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS}
              inputValues={inputValues}
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS,
                  e.target.value
                )
              }}
            />
          ),
          key: EPackageBenefitsItem.BOOK_WORKSHOP_DISCOUNT_PERCENTS,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_WORKSHOP),
        },
        {
          title: 'Free',
          key: EPackageBenefitsItem.BOOK_WORKSHOP_FREE,
          disabled: !checkedList.includes(EPackageBenefits.BOOK_WORKSHOP),
        },
      ],
    },
    {
      title: 'Book Festival ticket',
      key: EPackageBenefits.BOOK_FESTIVAL_TICKET,
      children: [
        {
          title: 'Early bird',
          key: EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_EARLY_BIRD,
          disabled: !checkedList.includes(
            EPackageBenefits.BOOK_FESTIVAL_TICKET
          ),
        },
        {
          title: (
            <CustomInput
              title="Discount"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS
                ]
              }
              disabled={
                !checkedList.includes(EPackageBenefits.BOOK_FESTIVAL_TICKET)
              }
              keyNote={
                EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS
              }
              onClick={() => {
                if (
                  !checkedList.includes(
                    EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS
                  )
                ) {
                  onCheck(
                    EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS
                  )
                }
              }}
              inputValues={inputValues}
              suffix="%"
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS,
                  e.target.value
                )
              }}
            />
          ),
          key: EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_DISCOUNT_PERCENTS,
          disabled: !checkedList.includes(
            EPackageBenefits.BOOK_FESTIVAL_TICKET
          ),
        },
        {
          title: (
            <CustomInput
              title="Free VIP access"
              suffix="per month"
              error={
                validationErrors[
                  EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS
                ]
              }
              disabled={
                !checkedList.includes(EPackageBenefits.BOOK_FESTIVAL_TICKET)
              }
              onChange={(e) => {
                handleChangeInput(
                  EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS,
                  e.target.value
                )
              }}
              onClick={() => {
                if (
                  !checkedList.includes(
                    EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS
                  )
                ) {
                  onCheck(
                    EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS
                  )
                }
              }}
              keyNote={
                EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS
              }
              inputValues={inputValues}
            />
          ),
          key: EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS,
          disabled: !checkedList.includes(
            EPackageBenefits.BOOK_FESTIVAL_TICKET
          ),
        },
      ],
    },
    {
      title: (
        <>
          Birthday present:
          <TextArea
            placeholder="Birthday present"
            name="subQuoteInDutch"
            onChange={(e) => {
              handleChangeInput(
                EPackageBenefitsItem.BIRTHDAY_PRESENT,
                e.target.value
              )
            }}
            value={
              inputValues?.filter(
                (item: any) =>
                  item.slug === EPackageBenefitsItem.BIRTHDAY_PRESENT
              )[0]
                ? (Object.values(
                    inputValues?.filter(
                      (item: any) =>
                        item.slug === EPackageBenefitsItem.BIRTHDAY_PRESENT
                    )[0]
                  )[1] as string)
                : undefined
            }
            containerClassName="mt-3 ml-20"
            onClick={() => {
              if (
                !checkedList.includes(EPackageBenefitsItem.BIRTHDAY_PRESENT)
              ) {
                onCheck(EPackageBenefitsItem.BIRTHDAY_PRESENT)
              }
            }}
            style={{
              width: '300px',
            }}
          />
        </>
      ),
      key: EPackageBenefits.BIRTHDAY_PRESENT,
    },
  ]
  useEffect(() => {
    if (!!selectedPackage && isEdit) {
      setValue('name', selectedPackage.name || '')
      setValue('nameInDutch', selectedPackage.nameInDutch || '')
      setValue(
        'priceUsd',
        selectedPackage.priceUsd !== null &&
          selectedPackage.priceUsd !== undefined
          ? `${selectedPackage.priceUsd}`
          : ''
      )
      if (selectedPackage?.media?.id && selectedPackage?.media?.url) {
        setValue('mediaId', selectedPackage?.media?.id)
        setImageSrc(selectedPackage?.media?.url)
      }
      setValue('textColor', selectedPackage.textColor || WHITE_COLOR)
      setValue(
        'priceEur',
        selectedPackage.priceEur !== null &&
          selectedPackage.priceEur !== undefined
          ? `${selectedPackage.priceEur}`
          : ''
      )
      setValue(
        'isActive',
        selectedPackage.isActive !== null &&
          selectedPackage.priceEur !== undefined
          ? !!selectedPackage.isActive
          : true
      )
      setValue('type', selectedPackage.type === EPackagePrivacy.PUBLIC)
      setValue(
        'packageConfig',
        selectedPackage?.packageBenefitItems?.map((item: any) => {
          return {
            slug: item.slug,
            ...item.metaData,
          }
        }) || []
      )
      setInputValues(
        selectedPackage?.packageBenefitItems?.map((item: any) => {
          return {
            slug: item.slug,
            ...item.metaData,
          }
        }) || []
      )

      const a = findCorrespondingBenefits(
        selectedPackage.packageBenefitItems?.map((item: any) => item.slug)
      )

      setCheckedList(
        selectedPackage.packageBenefitItems
          ?.map((item: any) => item.slug)
          .concat(a) || []
      )
    }
  }, [selectedPackage])

  const onCheck = (
    key: any,
    parent?: EPackageBenefits,
    isUncheckRest?: boolean,
    hasChildUnchecked?: boolean
  ) => {
    if (checkedList.includes(key)) {
      if (parents.includes(key)) {
        setCheckedList((list: any) => {
          return list.filter((item: any) => !item.startsWith(key))
        })
      } else {
        setCheckedList((list: any) => {
          return list.filter((item: any) => item !== key)
        })
      }
    } else if (parent && !checkedList.includes(key)) {
      if (isUncheckRest) {
        setCheckedList((list: any) => {
          return [
            ...list.filter(
              (item: string) => item === parent || !item.startsWith(parent)
            ),
            key,
          ]
        })
      } else if (hasChildUnchecked) {
        setCheckedList((list: any) => {
          return [
            ...list.filter(
              (item: string) =>
                item !==
                EPackageBenefitsItem.BOOK_RETREAT_FREE_STAY_AND_ACTIVITIES
            ),
            key,
          ]
        })
      } else {
        setCheckedList((list: any) => {
          return [
            ...list.filter((item: string) => !item.startsWith(parent + '_')),
            key,
          ]
        })
      }
    } else {
      setCheckedList((list: any) => {
        return [...list, key]
      })
    }
  }
  const handleFormSubmit = (data: any) => {
    const modifiedData = {
      ...data,
      type: data.type ? EPackagePrivacy.PUBLIC : EPackagePrivacy.PRIVATE,
      packageBenefitItems: inputValues,
      listSelect: uniq(filterListSelect(checkedList)),
    }

    // Call your original onSubmit function with the modified data
    onSubmit(modifiedData)
  }
  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {isEdit ? 'Edit package' : 'Add new package'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <form
          onSubmit={(data: any) => {
            const validList = checkedList.filter(
              (item) =>
                !parents.includes(item) &&
                !inputValues.map((item: any) => item.slug).includes(item)
            )
            for (let item of validList) {
              const output = validateRules(item, '0')
            }
            for (let i of childBenifit) {
              if (!validList.includes(i)) {
                setValidationErrors((validationErrors) => {
                  delete validationErrors[i]
                  return validationErrors
                })
              }
            }
            handleSubmit(handleFormSubmit)(data)
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Name"
                  name="name"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="nameInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Name in Netherlands"
                  name="nameInDutch"
                  required
                  onChange={onChange}
                  value={value}
                  errors={error?.message}
                  containerClassName="mt-4"
                />
              )
            }}
          />
          <Controller
            name="priceUsd"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Price (USD)"
                  name="priceUsd"
                  required
                  onChange={(e) => {
                    onChange(e.target.value.replace(/,/g, ''))
                  }}
                  value={value ? formatCurrencyInput(value) : value}
                  errors={error?.message}
                  containerClassName="mt-4"
                />
              )
            }}
          />
          <Controller
            name="priceEur"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Price (EUR)"
                  name="priceEur"
                  required
                  onChange={(e) => {
                    onChange(e.target.value.replace(/,/g, ''))
                  }}
                  value={value ? formatCurrencyInput(value) : value}
                  errors={error?.message}
                  containerClassName="mt-4"
                />
              )
            }}
          />

          <Controller
            name={'mediaId'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              onChangeDesktopRef.current = onChange
              return (
                <>
                  <CustomDragger
                    label="Image"
                    containerClassName="mt-[10px]"
                    onLoadEnd={(data) => {
                      setValue('mediaId', data?.id ?? 0)
                      onChange(data?.id ?? 0)
                      setImageSrc((prev) => data.original ?? prev)
                    }}
                    name="mediaId"
                    id="mediaId"
                    required
                    errors={error?.message}
                    multiple={false}
                    initResource={imageSrc}
                    allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                    limitFileSize={5}
                    uploadType={EMediaType.IMAGE as any}
                    uploadCategory={EMediaCategory.PACKAGE}
                  />
                </>
              )
            }}
          />
          <SelectMedia
            onClickSelectMedia={() => {
              setSelectedField((pre) => 'desktop')
              setShowModalSelectMedia(true)
            }}
          />
          <Controller
            name="textColor"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  label="Text color"
                  name="textColor"
                  type="color"
                  onChange={(e) => {
                    onChange(e?.target?.value)
                  }}
                  value={value}
                  errors={error?.message}
                  isDisableShadow
                />
              )
            }}
          />

          <p className="mt-[1.25rem]">* Access module:</p>
          <Controller
            name="packageConfig"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <div className=" ">
                  {treeDataTwo.map((packageBenefit) => (
                    <CheckListItem
                      key={packageBenefit.key}
                      packageBenefit={packageBenefit}
                      checkedList={checkedList}
                      onCheck={onCheck}
                    />
                  ))}
                </div>
              )
            }}
          />
          {isEdit && (
            <div>
              <Controller
                name="isActive"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="flex items-center mt-4">
                      <div className=" flex items-center">
                        <p className="mb-0 mr-4">Status</p>
                      </div>
                      <div>
                        <SwitchButton
                          disabled={isDefault}
                          size="default"
                          checked={value}
                          onChange={(e) => {
                            onChange(e)
                          }}
                        />
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          )}

          <div>
            <Controller
              name="type"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <div className="flex items-center mt-4">
                    <div className=" flex items-center">
                      <p className="mb-0 mr-4">Public</p>
                    </div>
                    <div>
                      <SwitchButton
                        disabled={isDefault}
                        size="default"
                        checked={value}
                        onChange={(e) => {
                          onChange(e)
                        }}
                      />
                    </div>
                  </div>
                )
              }}
            />
          </div>

          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="large"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="submit__btn login-btn"
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </AppModal>
  )
}

export default AddUpdatePackageManagementModal
function checkOutput(key: EPackageBenefitsItem, value: string) {
  const metaData = key.includes('discount')
    ? {
        discount: +value,
      }
    : key === EPackageBenefitsItem.BOOK_RETREAT_ADDITIONAL_FREE_NIGHTS
    ? {
        additionalTime: +value,
      }
    : key === EPackageBenefitsItem.BIRTHDAY_PRESENT
    ? {
        note: value,
      }
    : key === EPackageBenefitsItem.BOOK_FESTIVAL_TICKET_FREE_VIP_ACCESS ||
      key === EPackageBenefitsItem.LESSON_FREE_PREMIUM ||
      EPackageBenefitsItem.COMMUNITY_FREE_TRY_APP
    ? {
        freeTime: +value,
      }
    : key === EPackageBenefitsItem.BOOK_RETREAT_LIMITED_TO_VISIT
    ? {
        limitedTime: +value,
      }
    : {
        days: +value,
      }

  return {
    slug: key,
    ...metaData,
  }
}

function isEPackageBenefits(value: string): value is EPackageBenefits {
  return Object.values(EPackageBenefits).includes(value as EPackageBenefits)
}

function isEPackageBenefitsItem(value: string): value is EPackageBenefitsItem {
  return Object.values(EPackageBenefitsItem).includes(
    value as EPackageBenefitsItem
  )
}

function filterListSelect(items: string[]): string[] {
  const hasEPackageBenefits = items.some(isEPackageBenefits)
  const hasEPackageBenefitsItem = items.some(isEPackageBenefitsItem)

  if (hasEPackageBenefits && hasEPackageBenefitsItem) {
    // If both types of items exist, decide which to keep based on your criteria
    // For now, let's assume we keep EPackageBenefitsItem
    return items.filter(isEPackageBenefitsItem)
  } else {
    // If only one type exists, return the array as-is
    return items
  }
}
function findCorrespondingBenefits(checkedList: string[]): EPackageBenefits[] {
  const correspondingBenefits = new Set<EPackageBenefits>()

  for (const item of checkedList) {
    for (const benefit in EPackageBenefits) {
      if (item.startsWith(benefit.toLowerCase())) {
        correspondingBenefits.add(
          EPackageBenefits[benefit as keyof typeof EPackageBenefits]
        )
        break
      }
    }
  }

  return Array.from(correspondingBenefits)
}
