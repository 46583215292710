import {
  AlignRightOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DiffOutlined,
  EyeOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  EBlogNewsTabs,
  EBlogType,
  ELanguageBlogType,
  ELanguageBlogTypeLabel,
  PATH_BLOG_NEWS,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { ICategory, ISubCategory, RouterParams } from '@interfaces'
import {
  RootState,
  blogActions,
  getAllCategoriesAction,
  selectBlogLoading,
  useAppDispatch,
} from '@redux'
import { DatePicker, Tabs, Upload, message } from 'antd'
import type { UploadProps } from 'antd/es/upload'
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface'
import { t } from 'i18next'
import moment from 'moment'
import {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button, Input, SwitchButton, Text } from 'src/common'
import PreviewPhotoModal from 'src/components/chatChannel/PreviewPhotoModal'
import { IAddNewBlogValue, IResponseGetBlogDetail } from 'src/interfaces/blog'
import {
  addNewBlogAction,
  deleteBlogByIdAction,
  getBlogDetailAction,
  updateBlogByIdAction,
  uploadThumbnailAction,
} from 'src/redux/actions/blog-news'
import { getUserChat } from 'src/redux/actions/chat'
import styled from 'styled-components'
import * as z from 'zod'
import ContentTab from './content/ContentTab'
import KeywordTab from './content/KeywordTab'
import MetaTab from './content/MetaTab'
import AddNewBlogModal from 'src/components/blog/AddNewBlogModal'
import { useNavigate, useParams } from 'react-router-dom'
import DeleteBlogModal from 'src/components/blog/DeleteBlogModal'
import { customSlugify } from 'src/utils/customSlugify'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'

const optionsLanguage: { value: string; label: string }[] = [
  {
    value: ELanguageBlogType.EN,
    label: ELanguageBlogTypeLabel.EN,
  },
  {
    value: ELanguageBlogType.NL,
    label: ELanguageBlogTypeLabel.NL,
  },
]

interface ISingleBlogProps {}

const SingleBlog = ({}: ISingleBlogProps) => {
  const { blogId } = useParams<RouterParams['BlogDetail']>()
  let selectedBlog = useSelector((state: RootState) => state.blog.selectedBlog)

  selectedBlog = Boolean(blogId) ? selectedBlog : ({} as IResponseGetBlogDetail)

  const [activeTab, setActiveTab] = useState<string>(EBlogNewsTabs.CONTENT)
  const [dataSubmit, setDataSubmit] = useState<IAddNewBlogValue>(
    {} as IAddNewBlogValue
  )
  const [previewOpen, setPreviewOpen] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [detailImg, setDetailImg] = useState('')
  const [selectedTags, setSelectedTags] = useState<ICategory[]>([])
  const [isUploading, setIsUploading] = useState(false)
  const [slug, setSlug] = useState(selectedBlog?.metaData?.urlSlug || '')

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const [reset, setReset] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState(selectedBlog?.thumbnail?.url ?? '')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const authors = useSelector((state: RootState) => state.chat.listUserChat)
  const categories = useSelector(
    (state: RootState) => state.categories
  )?.categories

  const uploadThumbnailActionLoading = useSelector((state: RootState) =>
    selectBlogLoading(state, 'uploadThumbnailAction')
  )

  const getBlogDetailActionLoading = useSelector((state: RootState) =>
    selectBlogLoading(state, 'getBlogDetailActionLoading')
  )

  const blogSchema = z.object({
    title: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:blog_title_too_long') as string,
      }),
    content: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    urlSlug: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:slug_length_error') as string,
      }),
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    schemaMarkup: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    keywords: z
      .string({
        invalid_type_error: t('error:field_required') as string,
      })
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
    authorId: z.number({
      required_error: t('error:field_required') as string,
    }),
    postDate: z.string({
      invalid_type_error: t('error:field_required') as string,
    }),
    categoriesIds: z.array(z.number()).nonempty({
      message: t('error:field_required') as string,
    }),
    tagsIds: z.array(z.number()).nonempty({
      message: t('error:field_required') as string,
    }),
    type: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    published: z.boolean(),
    showAuthorName: z.boolean(),
    thumbnailId: z.number({
      required_error: t('error:required_thumbnail_error') as string,
    }),
    language: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    isDiscover: z.boolean(),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    trigger,
  } = useForm<IAddNewBlogValue>({
    defaultValues: {
      title: selectedBlog?.title || '',
      content: selectedBlog?.content || '',
      urlSlug: selectedBlog?.metaData?.urlSlug || '',
      metaTitle: selectedBlog?.metaData?.metaTitle || '',
      metaDescription: selectedBlog?.metaData?.metaDescription || '',
      canonical: selectedBlog?.metaData?.canonical || '',
      schemaMarkup: selectedBlog?.metaData?.schemaMarkup || 'schema mark up',
      metaImage: selectedBlog?.metaData?.metaImage || '',
      keywords: selectedBlog?.metaData?.keywords || '',
      authorId: selectedBlog?.authorId,
      postDate: moment(selectedBlog?.postDate).toString(),
      categoriesIds: selectedBlog?.categories?.map((item) => item.id) || [],
      tagsIds: selectedBlog?.tags?.map((item) => item.id) || [],
      type: selectedBlog?.type || EBlogType.ARTICLE,
      published: selectedBlog?.published || false,
      showAuthorName: selectedBlog?.showAuthorName || false,
      thumbnailId: selectedBlog?.thumbnailId,
      language: selectedBlog?.language || ELanguageBlogType.EN,
      isDiscover: selectedBlog?.isDiscover || false,
    },
    resolver: zodResolver(blogSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  // Check if have error in tabs
  const isContentTabError = useMemo(() => {
    return (
      !!errors?.content || !!errors.content?.message || !!errors?.content?.type
    )
  }, [errors?.content])

  const isMetaTabError = useMemo(() => {
    return (
      !!errors?.urlSlug ||
      !!errors?.metaTitle ||
      !!errors?.metaDescription ||
      !!errors?.canonical ||
      // !!errors?.schemaMarkup ||
      !!errors?.keywords ||
      !!errors?.metaImage
    )
  }, [
    errors?.urlSlug,
    errors?.metaTitle,
    errors?.metaDescription,
    errors?.canonical,
    // errors?.schemaMarkup,
    errors?.keywords,
    errors?.metaImage,
  ])
  // End check if have error in tabs

  const tabItems = [
    {
      label: (
        <Text
          className="blog-content-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isContentTabError ? 'red' : '#009BB7'}`,
            // padding: '16px 0',
          }}
        >
          <div className="label-wrapper-content">{EBlogNewsTabs.CONTENT}</div>
        </Text>
      ),
      key: EBlogNewsTabs.CONTENT,
      children: (
        <ContentTab
          activeTab={activeTab}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          errors={errors}
          getValues={getValues}
          contentName="content"
        />
      ),
    },
    {
      label: (
        <Text
          className="blog-meta-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isMetaTabError ? 'red' : '#009BB7'}`,
            // padding: '16px 0',
          }}
        >
          <div className="label-wrapper-content">{EBlogNewsTabs.META}</div>
        </Text>
      ),
      key: EBlogNewsTabs.META,
      children: (
        <MetaTab
          activeTab={activeTab}
          control={control}
          getValues={getValues}
          setValue={setValue}
          setIsUploading={setIsUploading}
          slug={customSlugify(slug)}
          isShowSelectMedia
          category={EMediaCategory.BLOG}
        />
      ),
    },
  ]

  // const handleChangeThumbnail: UploadProps['onChange'] = async (
  //   info: UploadChangeParam<UploadFile>
  // ) => {
  //   const isJpgOrPng =
  //     info.file.type === 'image/jpeg' || info.file.type === 'image/png'
  //   const isLt20M = Number(info.file.size) / 1024 / 1024 < 20

  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!')
  //     return
  //   } else if (!isLt20M) {
  //     message.error('Image must smaller than 20MB!')
  //     return
  //   }
  //   try {
  //     const response = await dispatch(
  //       uploadThumbnailAction(info.file.originFileObj as File)
  //     ).unwrap()

  //     if (response?.success) {
  //       setPreviewImage(response?.data?.url)
  //       setValue('thumbnailId', response?.data?.dataValues?.id)
  //     }
  //   } catch (error: any) {
  //     message.error(error?.message)
  //   }
  // }

  // const handlePreviewThumbnail = () => {
  //   setPreviewOpen(true)
  // }

  const onClose = () => {
    setPreviewOpen(false)
  }
  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false)
  }
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  // const handleClearThumbnail = () => {
  //   setPreviewImage('')
  //   setDetailImg('')
  // }

  const handleChangeCategories = (value: any) => {
    setValue('categoriesIds', value)

    if (value.length !== 0) {
      clearErrors(['categoriesIds'])
    }

    const selectedSubCategories = categories.filter((category) =>
      value.includes(category.id)
    )

    setSelectedTags(selectedSubCategories)
  }

  const onSubmit = (data: IAddNewBlogValue) => {
    setDataSubmit(() => data)
    setOpenConfirmModal(true)
  }

  const handleClickSave = () => {
    handleSubmit(onSubmit)()

    if (customSlugify(slug.trim()).length > 255) {
      setError('urlSlug', {
        type: 'maxLength',
        message: t('error:slug_length_error') as string,
      })
    }
  }

  const handleConfirmSubmit = async () => {
    if (blogId) {
      try {
        const response = await dispatch(
          updateBlogByIdAction({
            blogId: blogId,
            params: dataSubmit,
          })
        ).unwrap()

        if (response?.success) {
          message.success(response?.message)

          navigate(PATH_BLOG_NEWS)
        }
      } catch (error: any) {
        message.error(error?.message)
      }
    } else {
      try {
        const response = await dispatch(addNewBlogAction(dataSubmit)).unwrap()
        if (response?.success) {
          message.success('Create new blog successfully')

          navigate(PATH_BLOG_NEWS)
        }
      } catch (error: any) {
        message.error(error?.message)
      }
    }
  }

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        deleteBlogByIdAction(blogId as string)
      ).unwrap()

      if (response?.success) {
        message.success(response?.message)

        navigate(PATH_BLOG_NEWS)
      }
    } catch (error: any) {
      message.error(error?.message)
    }
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('thumbnailId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      // setImageMobileSrc(data?.baseUrl ?? '')
      // setValue('mediaMobileId', data?.id ?? 0)
      // onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
    // setIsChange(true)
  }

  useEffect(() => {
    const getDataRightSide = async () => {
      try {
        await dispatch(getAllCategoriesAction())
        await dispatch(getUserChat({ page: '1', limit: '1000', search: '' }))
      } catch (error: any) {}
    }
    getDataRightSide()
  }, [])

  useEffect(() => {
    if (blogId) {
      const getBlogDetail = async () => {
        try {
          const response = await dispatch(getBlogDetailAction(blogId)).unwrap()
          if (response?.success) {
            const blogDetail = response?.data
            const {
              title,
              content,
              metaData: {
                urlSlug,
                metaTitle,
                metaDescription,
                canonical,
                // schemaMarkup,
                metaImage,
                keywords,
              },

              authorId,
              postDate,
              categories: categoriesAPI,
              tags,
              type,
              published,
              showAuthorName,
              thumbnailId,
              language,
              isDiscover,
            } = blogDetail

            const selectedCategories = categoriesAPI.map(
              (category) => category.id
            )

            const selectedTags = tags.map((tag) => tag.id)
            const categoriesIds = categoriesAPI.map((category) => category.id)

            const selectedSubCategories = categories.filter((category) =>
              categoriesIds.includes(category.id)
            )

            setValue('title', title)
            setValue('content', content)
            setValue('urlSlug', urlSlug)
            setValue('metaTitle', metaTitle)
            setValue('metaDescription', metaDescription)
            setValue('canonical', canonical)
            // setValue('schemaMarkup', schemaMarkup)
            setValue('metaImage', metaImage)
            setValue('keywords', keywords)
            setValue('authorId', authorId)
            setValue('postDate', postDate)
            setValue('categoriesIds', selectedCategories)
            setValue('tagsIds', selectedTags)
            setValue('type', type)
            setValue('published', published)
            setValue('showAuthorName', showAuthorName)
            setValue('thumbnailId', thumbnailId)
            setValue('language', language)
            setValue('isDiscover', isDiscover ?? false)

            setSelectedTags(selectedSubCategories)
            setDetailImg(blogDetail?.thumbnail?.url)
          }
        } catch (error: any) {}
      }
      getBlogDetail()
    }
  }, [blogId, categories, dispatch, setValue])

  return (
    <SingleBlogStyled>
      {/* Top content */}
      <div className="blog-content-top intro-y mt-8 flex flex-col items-center sm:flex-row">
        <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
          {Boolean(blogId) ? 'Detail post' : 'Add new post'}
        </h2>
        <div className="blog-content-top item-right-wrapper flex items-center justify-between gap-2">
          {!!blogId ? (
            <Button
              type="default"
              size="small"
              icon={<DeleteOutlined />}
              className="flex items-center justify-start"
              onClick={() => {
                setOpenDeleteModal(true)
              }}
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
          <Controller
            name="language"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <ShareSelectInput
                  data={optionsLanguage}
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          />
          <Button
            type="primary"
            size="small"
            id="blog-form"
            onClick={() => {
              handleClickSave()
            }}
          >
            Save
          </Button>
        </div>
      </div>
      {/* End of top content */}

      {/* ========================================================== */}

      {/* Blog container */}
      <div className="blog-content-main intro-y mt-5 grid grid-cols-12 gap-5">
        <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8">
          <Controller
            name="title"
            defaultValue=""
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Title"
                  value={value}
                  onChange={(event) => {
                    setSlug((pre) => event?.target?.value?.trim())

                    onChange(event?.target?.value)
                    setValue(
                      'urlSlug',
                      customSlugify(event?.target?.value?.trim())
                    )
                  }}
                  errors={error?.message}
                  className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                />
              )
            }}
          />

          {/* Content main bottom */}
          <div className="intro-y box mt-5 overflow-hidden ">
            <Tabs
              destroyInactiveTabPane={true}
              defaultActiveKey={EBlogNewsTabs.CONTENT}
              type="card"
              size={'small'}
              items={tabItems}
              // defaultChecked={true}
              activeKey={activeTab}
              onChange={(e) => {
                setActiveTab(e)
              }}
            />
          </div>
        </div>

        {/* Right ------------------------------------------------------------------------------------------------ side */}
        <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
          <div className="intro-y box p-5 bg-white rounded-lg">
            <div className="blog-written-by">
              <Controller
                name="authorId"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <ShareSelectInput
                      data={authors.map((item, index) => {
                        return {
                          label: item.name,
                          value: item.id,
                        }
                      })}
                      value={value}
                      label="Written By"
                      placeholder="Select an author"
                      onChange={onChange}
                      errors={error?.message}
                      required
                    />
                  )
                }}
              />
            </div>
            <div className="blog-post-date mt-3">
              <div className="mb-2">Post Date</div>
              <Controller
                name="postDate"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Fragment>
                      <DatePicker
                        className="blog-time-select end-time-input w-full"
                        placeholder="Select a date"
                        disabledDate={(current) =>
                          current.isBefore(moment().subtract(1, 'day'))
                        }
                        value={value ? moment(value) : undefined}
                        onChange={(date, dateString) => {
                          setValue('postDate', date?.toString() as string)

                          if (dateString === '') {
                            setError('postDate', {
                              type: 'required',
                              message: 'Post date is required',
                            })
                          } else {
                            clearErrors(['postDate'])
                          }
                        }}
                        status={error?.message ? 'error' : ''}
                        format={'DD-MM-YYYY'}
                        style={{
                          height: '38px',
                          borderRadius: '0.375rem',
                          outline: 'none',
                        }}
                      />
                      {error && error.message && (
                        <p className="text-red-600 mt-2 text-sm">
                          {error.message}
                        </p>
                      )}
                    </Fragment>
                  )
                }}
              />
            </div>
            <div className="blog-categories mt-3">
              <Controller
                name="categoriesIds"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <ShareSelectInput
                      data={
                        !getBlogDetailActionLoading
                          ? categories &&
                            categories?.map((item, index) => {
                              return {
                                label: item.name,
                                value: item.id,
                              }
                            })
                          : []
                      }
                      label="Categories"
                      mode="multiple"
                      placeholder="Select categories"
                      value={value}
                      onChange={handleChangeCategories}
                      errors={error?.message}
                      required
                    />
                  )
                }}
              />
            </div>
            <div className="blog-tags mt-3">
              {blogId &&
              (getBlogDetailActionLoading || !selectedTags?.length) ? (
                <></>
              ) : (
                <Controller
                  name="tagsIds"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <ShareSelectInput
                        options={
                          getBlogDetailActionLoading || !selectedTags?.length
                            ? []
                            : (selectedTags?.map((item) => {
                                return {
                                  label: item.name,
                                  options: item.subCategories?.map((it) => ({
                                    label: it.name,
                                    value: it.id,
                                  })),
                                }
                              }) as any)
                        }
                        // data={selectedTags.map((item, index) => {
                        //   return {
                        //     label: item.name,
                        //     value: item.id,
                        //   }
                        // })}
                        label="Tags"
                        mode="multiple"
                        placeholder="Select tags"
                        onChange={(e) => {
                          onChange(e)
                        }}
                        value={value}
                        errors={error?.message}
                        onFocus={() => {
                          if (selectedTags.length === 0) {
                            message.warning('Please select categories first!')
                          }
                        }}
                        required
                      />
                    )
                  }}
                />
              )}
            </div>
            <div className="blog-type mt-3">
              <Controller
                name="type"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <ShareSelectInput
                      defaultValue={'Blog value'}
                      data={[
                        {
                          value: EBlogType.ARTICLE.toUpperCase(),
                          label: EBlogType.ARTICLE,
                        },
                        {
                          value: EBlogType.BLOG.toUpperCase(),
                          label: EBlogType.BLOG,
                        },
                        {
                          value: EBlogType.VLOG.toUpperCase(),
                          label: EBlogType.VLOG,
                        },
                        {
                          value: EBlogType.PODCAST.toUpperCase(),
                          label: EBlogType.PODCAST,
                        },
                        {
                          value: EBlogType.QUIZ.toUpperCase(),
                          label: EBlogType.QUIZ,
                        },
                      ]}
                      label="Type"
                      onChange={onChange}
                      value={value}
                    />
                  )
                }}
              />
            </div>
            <div className="blog-published mt-3">
              <div className="blog-published-label mb-2">Published</div>
              <Controller
                name="published"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchButton
                      size="default"
                      onChange={onChange}
                      checked={value}
                    />
                  )
                }}
              />
            </div>
            <div className="blog-published mt-3">
              <div className="blog-show-author-label mb-2">
                Show Author Name
              </div>
              <Controller
                name="showAuthorName"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchButton
                      size="default"
                      onChange={onChange}
                      checked={value}
                    />
                  )
                }}
              />
            </div>
            <div className="blog-published mt-3">
              <div className="blog-show-author-label mb-2">Discover to app</div>
              <Controller
                name="isDiscover"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchButton
                      size="default"
                      onChange={onChange}
                      checked={value}
                    />
                  )
                }}
              />
            </div>
            <div className="blog-published mt-3">
              <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                <div className="blog-add-thumbnail-label">
                  Thumbnail{' '}
                  <span
                    className="required"
                    style={{
                      color: '#d42a1c',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    *{' '}
                  </span>
                </div>
                {/* {!!previewImage || !!detailImg ? (
                  <>
                    <div
                      className="blog-add-thumbnail-clear-thumbnail flex items-center justify-center hover:bg-[#c6c6c6] rounded-full p-1"
                      onClick={handleClearThumbnail}
                    >
                      <div className="mr-1 cursor-pointer">Clear</div>
                      <CloseCircleOutlined />
                    </div>
                  </>
                ) : (
                  <></>
                )} */}
              </div>
              {previewImage || detailImg ? (
                // <img
                //   src={previewImage || detailImg}
                //   alt="blog-thumbnail"
                //   style={{ width: '100%', objectFit: 'contain' }}
                //   onClick={handlePreviewThumbnail}
                // />
                <>
                  <Controller
                    name={'thumbnailId'}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      onChangeDesktopRef.current = onChange
                      return (
                        <>
                          <CustomDragger
                            // label="Image"
                            containerClassName="mt-[10px]"
                            onLoadEnd={(data) => {
                              setValue('thumbnailId', data?.id ?? 0)
                              onChange(data?.id ?? 0)
                              // setIsChange(true)
                            }}
                            name="mediaId"
                            id="mediaId"
                            required
                            errors={error?.message}
                            multiple={false}
                            initResource={imageSrc}
                            reset={reset}
                            allowFileTypes={[
                              'image/png',
                              'image/jpeg',
                              'image/jpg',
                            ]}
                            limitFileSize={5}
                            changeLoading={setIsModalLoading}
                            uploadType={EMediaType.IMAGE as any}
                            uploadCategory={EMediaCategory.BLOG}
                          />
                        </>
                      )
                    }}
                  />
                  <SelectMedia
                    onClickSelectMedia={() => {
                      setSelectedField((pre) => 'desktop')
                      setShowModalSelectMedia(true)
                    }}
                    isNotGrid={true}
                  />
                </>
              ) : (
                // <Controller
                //   name="thumbnailId"
                //   control={control}
                //   render={({
                //     field: { value, onChange },
                //     fieldState: { error },
                //   }) => {
                //     return (
                //       <Fragment>
                //         <Upload
                //           name="blog-thumbnail"
                //           listType="picture-card"
                //           className="blog-thumbnail-uploader"
                //           showUploadList={false}
                //           onChange={handleChangeThumbnail}
                //           accept="image/png, image/jpeg, image/jpg"
                //         >
                //           <div>
                //             {uploadThumbnailActionLoading ? (
                //               <LoadingOutlined />
                //             ) : (
                //               <PlusOutlined />
                //             )}
                //             <div style={{ marginTop: 8 }}>
                //               {uploadThumbnailActionLoading
                //                 ? 'Uploading'
                //                 : 'Upload'}
                //             </div>
                //           </div>
                //         </Upload>
                //         {error && error.message && (
                //           <p className="text-red-600 mt-2 text-sm">
                //             {error.message}
                //           </p>
                //         )}
                //       </Fragment>
                //     )
                //   }}
                // />
                <>
                  <Controller
                    name={'thumbnailId'}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      onChangeDesktopRef.current = onChange
                      return (
                        <>
                          <CustomDragger
                            // label="Image"
                            containerClassName="mt-[10px]"
                            onLoadEnd={(data) => {
                              setValue('thumbnailId', data?.id ?? 0)
                              onChange(data?.id ?? 0)
                              // setIsChange(true)
                            }}
                            name="mediaId"
                            id="mediaId"
                            required
                            errors={error?.message}
                            multiple={false}
                            initResource={imageSrc}
                            reset={reset}
                            allowFileTypes={[
                              'image/png',
                              'image/jpeg',
                              'image/jpg',
                            ]}
                            limitFileSize={5}
                            changeLoading={setIsModalLoading}
                            uploadType={EMediaType.IMAGE as any}
                            uploadCategory={EMediaCategory.BLOG}
                          />
                        </>
                      )
                    }}
                  />
                  <SelectMedia
                    onClickSelectMedia={() => {
                      setSelectedField((pre) => 'desktop')
                      setShowModalSelectMedia(true)
                    }}
                    isNotGrid={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <PreviewPhotoModal
        open={previewOpen}
        onDelete={() => {}}
        onSave={() => {}}
        onClose={onClose}
        url={previewImage || detailImg}
      />
      <AddNewBlogModal
        open={openConfirmModal}
        content={
          'This post will be saved and published on your setting time, make sure you have been checking all content.'
        }
        onSave={handleConfirmSubmit}
        onClose={onCloseConfirmModal}
      />
      <DeleteBlogModal
        open={openDeleteModal}
        content={'Are you sure to delete this blog/news?'}
        onSave={handleConfirmDelete}
        onClose={onCloseDeleteModal}
      />
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.BLOG}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </SingleBlogStyled>
  )
}

export default SingleBlog

const SingleBlogStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },

    '.ant-upload.ant-upload-select-picture-card': {
      width: '100%',
      height: 'auto',
    },
  }
})
