import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { ShareSelectInput } from '@components'
import {
  BLOG_STATUS,
  PATH_ADD_BLOG_NEWS,
  PATH_BLOG_NEWS,
  enumTableTitleBlogNews,
} from '@configs'
import { RootState, useAppDispatch, selectBlogLoading } from '@redux'
import { Skeleton, Tooltip, Typography, message } from 'antd'
import { el } from 'date-fns/locale'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import { IBlogParams } from 'src/interfaces/blog'
import { IGetGoalParams } from 'src/interfaces/habit-management'
import {
  deleteBlogByIdAction,
  getBlogDetailAction,
  getListBlogAction,
} from 'src/redux/actions/blog-news'
import { getListGoalsAction } from 'src/redux/actions/habit-management'
import styled from 'styled-components'

const optionsFilterStatus: { value: string; label: string }[] = [
  {
    value: 'all status',
    label: 'All status',
  },
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'unpublished',
    label: 'Unpublished',
  },
]

type Props = {}

const BlogNewsPage = (props: Props) => {
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [isSearching, setIsSearching] = useState(false)
  const debouncedValue = useDebounce<string | null>(searchValue, 800)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [status, setStatus] = useState(optionsFilterStatus[0].label)
  const [selectedBlogId, setSelectedBlogId] = useState<number | string>()
  const [isModalLoading, setIsModalLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { blogs, totalBlogs, pageBlogs, limitBlogs, selectedBlog } =
    useSelector((state: RootState) => state.blog)

  const getBlogs = async (params?: IBlogParams) => {
    if (params?.status !== '') {
      dispatch(
        getListBlogAction({
          page: +pageBlogs,
          sort,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          ...(params || {}),
        })
      )
    } else {
      dispatch(
        getListBlogAction({
          page: +pageBlogs,
          sort,
          status: status,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
          ...(params || {}),
        })
      )
    }
  }

  const getBlogsBySearchValue = async () => {
    try {
      setIsSearching(true)
      await dispatch(
        getListBlogAction({
          page: 1,
          search:
            debouncedValue !== null && debouncedValue.trim()
              ? debouncedValue.trim()
              : undefined,
        })
      ).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setIsSearching(false)
    }
  }

  const getListBlogActionLoading = useSelector((state: RootState) =>
    selectBlogLoading(state, 'getListBlogAction')
  )

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }

  const handleConfirmDelete = async () => {
    try {
      if (selectedBlogId) {
        setIsModalLoading(true)

        const response = await dispatch(
          deleteBlogByIdAction(selectedBlogId as string)
        ).unwrap()

        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          getBlogs({ page: 1 })
        }
      } else {
        setIsModalLoading(false)
        setOpenConfirmDeleteModal(false)
      }
    } catch (error: any) {
      console.log(error?.message)
      message.error(error?.message)
    }
  }

  const columns = [
    {
      width: '5%',
      title: enumTableTitleBlogNews.NO,
      dataIndex: 'index',
      key: 'index',
      ellipsis: true,
      align: 'center',
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-start w-full">
            <p className="m-0">{enumTableTitleBlogNews.TITLE}</p>
          </div>
        )
      },
      dataIndex: 'title',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center max-w-[460px]">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'nowrap',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '12.5%',
      title: () => {
        return (
          <div className="flex items-center justify-start w-full">
            <p className="m-0">{enumTableTitleBlogNews.AUTHOR}</p>
          </div>
        )
      },
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center w-full">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'nowrap',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
          </div>
        )
      },
      dataIndex: 'author',
      key: 'id',
      // render: (e: string) => {
      //   return <div className="flex items-center justify-center">{e}</div>
      // },
      ellipsis: true,
    },
    {
      width: '5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleBlogNews.TYPE}</p>
          </div>
        )
      },
      dataIndex: 'type',
      key: 'type',
      render: (e: string) => {
        return <div className="flex items-center justify-center">{e}</div>
      },
      ellipsis: true,
    },
    {
      width: '12.5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleBlogNews.CREATE_TIME}</p>
            <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  ...sort,
                  postDate: sort.postDate === 'asc' ? 'desc' : 'asc',
                })
                if (status !== BLOG_STATUS.ALL) {
                  getBlogs({
                    // status: status,
                    sort: {
                      ...sort,
                      postDate: sort.postDate === 'asc' ? 'desc' : 'asc',
                    },
                  })
                } else {
                  getBlogs({
                    status: status,
                    sort: {
                      ...sort,
                      postDate: sort.postDate === 'asc' ? 'desc' : 'asc',
                    },
                  })
                }
              }}
            >
              {sort.postDate === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div>
          </div>
        )
      },
      dataIndex: 'postDate',
      key: 'postDate',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center">
            {moment(e).format('HH:mm:ss - DD MMM YYYY')}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '5%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleBlogNews.STATUS}</p>
            {/* <div
              className="flex items-center ml-2 cursor-pointer"
              onClick={() => {
                setSort({
                  ...sort,
                  published: sort.published === 'asc' ? 'desc' : 'asc',
                })
                getBlogs({
                  sort: {
                    ...sort,
                    published: sort.published === 'asc' ? 'desc' : 'asc',
                  },
                })
              }}
            >
              {sort.published === 'asc' ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </div> */}
          </div>
        )
      },
      dataIndex: 'status',
      key: 'status',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center  capitalize">
            {e}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '7%',
      title: enumTableTitleBlogNews.ACTION,
      key: 'action',
      dataIndex: 'action',
      render: (_: any, record: { id: any }) => (
        <div className="flex space-x-4 justify-center items-center">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={async () => {
                try {
                  const response = await dispatch(
                    getBlogDetailAction(record.id)
                  ).unwrap()
                } catch (error: any) {
                  console.log(error?.message)
                }

                navigate(`${PATH_BLOG_NEWS}/blog-news-detail/${record.id}`)
              }}
            />
          </Tooltip>
          <DeleteOutlined
            className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
            onClick={() => {
              setOpenConfirmDeleteModal(true)
              setSelectedBlogId(record.id)
            }}
          />
        </div>
      ),
      ellipsis: true,
      align: 'center',
    },
  ]

  const handleChaneStatus = (value: string) => {
    if (value === BLOG_STATUS.ALL) {
      setStatus(BLOG_STATUS.ALL)
      getBlogs({ page: 1 })
    } else if (value === BLOG_STATUS.PUBLISHED) {
      setStatus(BLOG_STATUS.PUBLISHED)
      getBlogs({ page: 1, status: 1 })
    } else if (value === BLOG_STATUS.UNPUBLISHED) {
      setStatus(BLOG_STATUS.UNPUBLISHED)
      getBlogs({ page: 1, status: 0 })
    }
  }

  useEffect(() => {
    getBlogs({ page: 1 })
  }, [])

  useEffect(() => {
    debouncedValue !== null && getBlogsBySearchValue()
  }, [debouncedValue])

  return (
    <BlogManagementStyled>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[700px] flex-1 flex gap-8">
          <Input
            prefix={<SearchOutlined className="pl-2" />}
            placeholder="Enter title or author name"
            value={searchValue === null ? '' : searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
              if (e.target.value === '') {
                setStatus(optionsFilterStatus[0].label)
              }
            }}
            suffix={isSearching ? <Loading /> : undefined}
          />
          <div className="wrapper-status-filter flex items-center justify-center gap-1">
            <div className="label-status">Status</div>
            <ShareSelectInput
              data={optionsFilterStatus}
              // defaultValue={optionsFilterStatus[0].label}
              value={status}
              style={{ width: '160px' }}
              onChange={handleChaneStatus}
            />
          </div>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              // setOpen(true)
              navigate(PATH_ADD_BLOG_NEWS)
            }}
          >
            Add new
          </Button>
        </div>
      </div>
      <div className="pb-[1.25rem]">
        {getListBlogActionLoading || isSearching ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <SharedTable
            columns={columns as any}
            dataSource={blogs.map((item, index) => {
              return {
                index: index + 1,
                key: item?.id,
                id: item?.id,
                title: item?.title,
                author: item?.author?.name,
                type: item?.type,
                postDate: item?.postDate,
                status: item?.published ? 'Published' : 'Unpublished',
              }
            })}
            paginationProps={{
              total: !!totalBlogs ? +totalBlogs : undefined,
              pageSize: +limitBlogs || 10,
              current: +pageBlogs,
              onChange(page) {
                const params: { page: number; status?: number | string } = {
                  page: page,
                }

                switch (status) {
                  case BLOG_STATUS.PUBLISHED:
                    params.status = 1
                    break
                  case BLOG_STATUS.UNPUBLISHED:
                    params.status = 0
                    break
                  default:
                    break
                }

                getBlogs(params)
              },
            }}
            sticky
          />
        )}
      </div>
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete blog/news"
          content="Are you sure to delete this blog/news?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={handleConfirmDelete}
          onClose={onCloseConfirmModal}
        />
      )}
    </BlogManagementStyled>
  )
}

export default BlogNewsPage

const BlogManagementStyled = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },
  }
})
