import React, { useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import { t } from 'i18next'
import { Card, Skeleton, message } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState, selectContentLoading, useAppDispatch } from '@redux'
import { zodResolver } from '@hookform/resolvers/zod'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import {
  ECommonContentLayout,
  EPackagePageTabs,
  LAYOUT_OPTIONS,
  thunkActionLoading,
} from '@configs'
import { Button, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from '../gallery/SelectMedia'
import ConfirmDeleteModal from '../staff-and-role-management/ConfirmDeleteModal'
import SelectMediaModal from '../gallery/SelectMediaModal'
import { IDataBannerTab } from 'src/interfaces/package-page-management'
import {
  getBannerPackageAction,
  updateBannerPackageAction,
} from 'src/redux/actions/content-management'
import { ShareSelectInput } from '../shared'
import queryString from 'query-string'

type Props = { activeTab?: string }

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:title_blog_list_length_error') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:title_netherlands_blog_list_length_error') as string,
    }),
  mediaDescription: z
    .string()
    .trim()

    .max(255, {
      message: t('error:landing_description_length_error') as string,
    }),
  mediaDescriptionInDutch: z
    .string()
    .trim()

    .max(255, {
      message: t('error:landing_description_length_error') as string,
    }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaMobileId: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
  textColor: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(10, {
      message: t('error:text_color_length_error') as string,
    }),
  layout: z.string(),
})

const BannerTab = ({ activeTab }: Props) => {
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const dispatch = useAppDispatch()

  const bannerLoading = useSelector((state: RootState) =>
    selectContentLoading(state, thunkActionLoading.GET_PACKAGE_BANNER_LOADING)
  )
  const packageBanner = useSelector(
    (state: RootState) => state.content.packagePage?.banner
  )

  const [reset, setReset] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState(packageBanner?.media?.url ?? '')
  const [imageMobileSrc, setImageMobileSrc] = useState(
    packageBanner?.mediaMobile?.url ?? ''
  )
  const [showCancelModal, setShowCancelModal] = useState(false)

  const defaultValue: IDataBannerTab = {
    title: packageBanner?.title ?? '',
    titleInDutch: packageBanner?.titleInDutch ?? '',
    mediaDescription: packageBanner?.mediaDescription ?? '',
    mediaDescriptionInDutch: packageBanner?.mediaDescriptionInDutch ?? '',
    mediaId: packageBanner?.mediaId ?? 0,
    mediaMobileId: packageBanner?.mediaMobileId || 0,
    textColor: packageBanner?.metaData?.textColor ?? '',
    layout: ECommonContentLayout.LAYOUT_ONE,
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
    setError,
  } = useForm<IDataBannerTab>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  useEffect(() => {
    if (activeTab === EPackagePageTabs.BANNER) {
      dispatch(getBannerPackageAction())
    }
  }, [activeTab])

  useEffect(() => {
    setValue('mediaId', packageBanner?.mediaId ?? 0)
    setValue('mediaMobileId', packageBanner?.mediaMobileId ?? 0)
    setValue('title', packageBanner?.title ?? '')
    setValue('titleInDutch', packageBanner?.titleInDutch ?? '')
    setValue('mediaDescription', packageBanner?.mediaDescription ?? '')
    setValue(
      'mediaDescriptionInDutch',
      packageBanner?.mediaDescriptionInDutch ?? ''
    )
    setValue('textColor', packageBanner?.metaData.textColor ?? '')
    setImageSrc(packageBanner?.media?.url ?? '')
    setImageMobileSrc(packageBanner?.mediaMobile?.url ?? '')
    setValue('layout', packageBanner?.metaData?.layout)
  }, [packageBanner])

  const onCancel = () => {
    setValue('mediaId', packageBanner?.mediaId ?? 0)
    setValue('mediaMobileId', packageBanner?.mediaMobileId ?? 0)
    setValue('title', packageBanner?.title ?? '')
    setValue('titleInDutch', packageBanner?.titleInDutch ?? '')
    setValue('mediaDescription', packageBanner?.mediaDescription ?? '')
    setValue(
      'mediaDescriptionInDutch',
      packageBanner?.mediaDescriptionInDutch ?? ''
    )
    setValue('textColor', packageBanner?.metaData.textColor ?? '')
    setImageSrc(packageBanner?.media?.url ?? '')
    setImageMobileSrc(packageBanner?.mediaMobile?.url ?? '')
    setReset((r) => !r)
  }

  const onSave = async (data: IDataBannerTab) => {
    try {
      const response = await dispatch(updateBannerPackageAction(data)).unwrap()
      if (response?.packageBanner) {
        message.success(response.message)
      }
    } catch (error) {
      throw error
    }
  }

  const onPreview = (data: IDataBannerTab) => {
    const payload = {
      title: data.title,
      url: imageSrc,
      type: 'banner',
      textColor: data.textColor,
      mediaDescription: data.mediaDescription,
      layout: data.layout,
    }
    const stringified = queryString.stringify(payload)

    const URL = `${process.env.REACT_APP_PUBLIC_WEB_DOMAIN}/preview-content?${stringified}`
    window.open(URL, '_blank')
  }

  const onClickCancel = () => {
    setShowCancelModal(true)
  }

  const onCloseCancelModal = () => {
    setShowCancelModal(false)
  }

  const onConfirmCancel = () => {
    onCancel()
    setShowCancelModal(false)
  }
  return bannerLoading ? (
    <Skeleton paragraph={{ rows: 4 }} className="mt-5" />
  ) : (
    <Card>
      <form>
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="row"
                label="Title"
                required
                size="middle"
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                }}
                errors={error?.message}
                style={{ marginTop: 10 }}
              />
            )
          }}
        />
        <Controller
          name="titleInDutch"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="row"
                label="Title in Netherlands"
                required
                size="middle"
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                }}
                errors={error?.message}
                style={{ marginTop: 10 }}
              />
            )
          }}
        />

        <Controller
          name="mediaDescription"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="row"
                label="Description"
                size="middle"
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                }}
                errors={error?.message}
                style={{ marginTop: 10 }}
              />
            )
          }}
        />
        <Controller
          name="mediaDescriptionInDutch"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="row"
                label="Description in Netherlands"
                size="middle"
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                }}
                errors={error?.message}
                style={{ marginTop: 10 }}
              />
            )
          }}
        />

        <Controller
          name={'mediaId'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeDesktopRef.current = onChange
            return (
              <>
                <CustomDragger
                  label="Image"
                  containerClassName="mt-[10px]"
                  onLoadEnd={(data) => {
                    setValue('mediaId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                    setImageSrc((prev) => data.original ?? prev)
                  }}
                  name="mediaId"
                  id="mediaId"
                  required
                  errors={error?.message}
                  multiple={false}
                  initResource={imageSrc}
                  reset={reset}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={setIsModalLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.HOME_PAGE}
                />
              </>
            )
          }}
        />
        <SelectMedia
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'desktop')
            setShowModalSelectMedia(true)
          }}
        />

        <Controller
          name={'mediaMobileId'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeMobileRef.current = onChange
            return (
              <>
                <CustomDragger
                  label="Image mobile"
                  containerClassName="mt-[10px]"
                  onLoadEnd={(data) => {
                    setValue('mediaMobileId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                  }}
                  name="mediaMobileId"
                  id="mediaMobileId"
                  required
                  errors={error?.message}
                  multiple={false}
                  initResource={imageMobileSrc}
                  reset={reset}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={setIsModalLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.HOME_PAGE}
                />
              </>
            )
          }}
        />
        <SelectMedia
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'mobile')
            setShowModalSelectMedia(true)
          }}
        />
        <Controller
          name="layout"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ShareSelectInput
                containerClassName="grid grid-cols-7 gap-3 mt-4"
                labelClassName="col-span-2 flex items-center"
                // className="col-span-5 "
                inlineLabelClassName="!block !m-0"
                data={LAYOUT_OPTIONS}
                onChange={(value: string) => {
                  console.log({ value })
                  onChange(value)
                }}
                label={'Layout'}
                errors={error?.message}
                value={value}
              />
            )
          }}
        />
        <Controller
          name="textColor"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Text color"
                name="textColor"
                type="color"
                required
                onChange={(e) => {
                  onChange(e?.target?.value)
                }}
                value={value}
                errors={error?.message}
                isDisableShadow
              />
            )
          }}
        />
      </form>
      <div
        style={{
          display: 'flex',
          marginTop: 24,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />

        <Button
          className="mr-5"
          type="primary"
          onClick={handleSubmit(onPreview)}
        >
          Preview
        </Button>

        <Button
          htmlType="submit"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
            marginRight: 24,
          }}
          // disabled={!isDirty}
          onClick={onClickCancel}
        >
          {'Reset'}
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
          }}
          loading={isModalLoading || false}
          // disabled={!isDirty}
          onClick={handleSubmit(onSave)}
        >
          {'Save'}
        </Button>
      </div>
      <ConfirmDeleteModal
        title="Reset all data"
        content="Are you sure you want to discard all your change?"
        open={showCancelModal}
        isLoading={!!isModalLoading}
        onDelete={onConfirmCancel}
        onClose={onCloseCancelModal}
      />
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </Card>
  )
}

export default BannerTab
