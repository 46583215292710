import { zodResolver } from '@hookform/resolvers/zod'
import { Card, Skeleton, message } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { z } from 'zod'

import { RootState, selectContentLoading, useAppDispatch } from '@redux'
import { EPackagePageTabs, URL_REGEX, thunkActionLoading } from '@configs'
import { Button, Input, TextArea } from 'src/common'
import { IDataCommunityTab } from 'src/interfaces/package-page-management'
import {
  getCommunityPackageAction,
  updateCommunityPackageAction,
} from 'src/redux/actions/content-management'
import ConfirmDeleteModal from '../staff-and-role-management/ConfirmDeleteModal'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from '../gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'

type Props = { activeTab?: string }

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:title_blog_list_length_error') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:title_netherlands_blog_list_length_error') as string,
    }),
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(1000, {
      message: t('error:content_community_tab_length_error') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(1000, {
      message: t(
        'error:content_netherlands_community_tab_length_error'
      ) as string,
    }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaMobileId: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
  button: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(20, {
      message: 'Button title  must be less than 20 characters long',
    }),
  buttonLink: z.optional(
    z.union([
      z
        .string()
        .trim()
        .max(255, {
          message: 'Content  must be less than 255 characters long',
        })
        .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
      z.string().trim().length(0),
    ])
  ),
  buttonInDutch: z
    .string()
    .trim()
    // .nonempty({
    //   message: t('error:field_required') as string,
    // })
    .max(20, {
      message: 'Button title  must be less than 20 characters long',
    }),
})

const CommunityTab = ({ activeTab }: Props) => {
  const dispatch = useAppDispatch()
  const communityLoading = useSelector((state: RootState) =>
    selectContentLoading(state, thunkActionLoading.GET_COMMUNITY_BANNER_LOADING)
  )
  const [isModalLoading, setIsModalLoading] = useState(false)
  const onCloseCancelModal = () => {
    setShowCancelModal(false)
  }
  const [reset, setReset] = useState(false)

  const [showCancelModal, setShowCancelModal] = useState(false)

  const [imageSrc, setImageSrc] = useState('')
  const [imageMobileSrc, setImageMobileSrc] = useState('')
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)

  const packageCommunity = useSelector(
    (state: RootState) => state.content.packagePage?.community
  )
  const onConfirmCancel = () => {
    onCancel()
    setShowCancelModal(false)
  }
  const onCancel = () => {
    setValue('title', packageCommunity?.title ?? '')
    setValue('titleInDutch', packageCommunity?.titleInDutch ?? '')
    setValue('content', packageCommunity?.content ?? '')
    setValue('contentInDutch', packageCommunity?.contentInDutch ?? '')
    setValue('buttonInDutch', packageCommunity?.metaData?.buttonInDutch ?? '')
    setValue('button', packageCommunity?.metaData?.button ?? '')
    setValue('buttonLink', packageCommunity?.metaData?.buttonLink ?? '')
    setValue('mediaId', packageCommunity?.mediaId ?? 0)
    setValue('mediaMobileId', packageCommunity?.mediaMobileId ?? 0)
  }
  const defaultValue: any = {
    title: packageCommunity?.title ?? '',
    titleInDutch: packageCommunity?.titleInDutch ?? '',
    content: packageCommunity?.content ?? '',
    contentInDutch: packageCommunity?.contentInDutch ?? '',
    mediaId: packageCommunity?.mediaId ?? 0,
    mediaMobileId: packageCommunity?.mediaMobileId ?? 0,
    button: packageCommunity?.metaData?.button ?? '',
    buttonLink: packageCommunity?.metaData?.buttonLink ?? '',
    buttonInDutch: packageCommunity?.metaData?.buttonInDutch ?? '',
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm<IDataCommunityTab>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  const onSave = async (data: IDataCommunityTab) => {
    try {
      const response = await dispatch(
        updateCommunityPackageAction(data)
      ).unwrap()
      if (response?.packageCommunity) {
        message.success(response.message)
        // setIsChange(false)
      }
    } catch (error) {
      throw error
    }
  }
  const onClickCancel = () => {
    setShowCancelModal(true)
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  useEffect(() => {
    if (activeTab === EPackagePageTabs.COMMUNITY) {
      dispatch(getCommunityPackageAction())
    }
  }, [activeTab])

  useEffect(() => {
    if (packageCommunity) {
      setValue('title', packageCommunity?.title ?? '')
      setValue('titleInDutch', packageCommunity?.titleInDutch ?? '')
      setValue('content', packageCommunity?.content ?? '')
      setValue('contentInDutch', packageCommunity?.contentInDutch ?? '')
      setValue('buttonInDutch', packageCommunity?.metaData?.buttonInDutch ?? '')
      setValue('button', packageCommunity?.metaData?.button ?? '')
      setValue('buttonLink', packageCommunity?.metaData?.buttonLink ?? '')
      setValue('mediaId', packageCommunity?.mediaId ?? 0)
      setValue('mediaMobileId', packageCommunity?.mediaMobileId ?? 0)
      setImageSrc(packageCommunity?.media?.url ?? '')
      setImageMobileSrc(packageCommunity?.mediaMobile?.url ?? '')
    }
  }, [packageCommunity])

  return communityLoading ? (
    <Skeleton paragraph={{ rows: 4 }} className="mt-5" />
  ) : (
    <Card>
      <form>
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Title"
                name="title"
                required
                onChange={onChange}
                value={value}
                style={{ marginTop: 10, height: 'fit-content' }}
                errors={error?.message}
                isGrid
                containerClassName="mt-3"
                rows={5}
                disableResize={true}
              />
            )
          }}
        />
        <Controller
          name="titleInDutch"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Title in Netherlands"
                name="titleInDutch"
                required
                onChange={onChange}
                value={value}
                style={{ marginTop: 10, height: 'fit-content' }}
                errors={error?.message}
                isGrid
                containerClassName="mt-3"
                rows={5}
                disableResize={true}
              />
            )
          }}
        />
        <Controller
          name="content"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Content"
                name="content"
                required
                onChange={onChange}
                value={value}
                style={{ marginTop: 10, height: 'fit-content' }}
                errors={error?.message}
                isGrid
                containerClassName="mt-3"
                rows={6}
                disableResize={true}
              />
            )
          }}
        />
        <Controller
          name="contentInDutch"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Content in Netherlands"
                name="contentInDutch"
                required
                onChange={onChange}
                value={value}
                style={{ marginTop: 10, height: 'fit-content' }}
                errors={error?.message}
                isGrid
                containerClassName="mt-3"
                rows={6}
                disableResize={true}
              />
            )
          }}
        />
        <Controller
          name={'button'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Button title"
                name="button"
                onChange={onChange}
                value={value}
                style={{ marginTop: 10 }}
                errors={error?.message}
              />
            )
          }}
        />
        <Controller
          name={'buttonInDutch'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Button title in Netherlands"
                name="buttonInDutch"
                onChange={onChange}
                value={value}
                style={{ marginTop: 10 }}
                errors={error?.message}
              />
            )
          }}
        />
        <Controller
          name={'mediaId'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeDesktopRef.current = onChange
            return (
              <>
                <CustomDragger
                  label="Image"
                  containerClassName="mt-[10px]"
                  onLoadEnd={(data) => {
                    setValue('mediaId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                  }}
                  name="mediaId"
                  id="mediaId"
                  required
                  errors={error?.message}
                  multiple={false}
                  initResource={imageSrc}
                  reset={reset}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={setIsModalLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.HOME_PAGE}
                />
              </>
            )
          }}
        />
        <SelectMedia
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'desktop')
            setShowModalSelectMedia(true)
          }}
        />

        <Controller
          name={'mediaMobileId'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeMobileRef.current = onChange
            return (
              <>
                <CustomDragger
                  label="Image mobile"
                  containerClassName="mt-[10px]"
                  onLoadEnd={(data) => {
                    setValue('mediaMobileId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                  }}
                  name="mediaMobileId"
                  id="mediaMobileId"
                  required
                  errors={error?.message}
                  multiple={false}
                  initResource={imageMobileSrc}
                  reset={reset}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={setIsModalLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.PACKAGE}
                />
              </>
            )
          }}
        />
        <SelectMedia
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'mobile')
            setShowModalSelectMedia(true)
          }}
        />
      </form>
      <div
        style={{
          display: 'flex',
          marginTop: 24,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        <Button
          htmlType="submit"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
            marginRight: 24,
          }}
          disabled={!isDirty}
          onClick={onClickCancel}
        >
          {'Reset'}
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
          }}
          loading={false}
          disabled={!isDirty}
          onClick={handleSubmit(onSave)}
        >
          {'Save'}
        </Button>
        <ConfirmDeleteModal
          title="Reset all data"
          content="Are you sure you want to discard all your change?"
          open={showCancelModal}
          isLoading={!!isModalLoading}
          onDelete={onConfirmCancel}
          onClose={onCloseCancelModal}
        />
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </Card>
  )
}

export default CommunityTab
